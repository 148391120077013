import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./staff.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const TeamView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { staffId } = useParams();
  const [fullName, setFullName] = useState("");
  const [eduQualification, setEduQualification] = useState("");
  const [designation, setDesignation] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [profile, setProfile] = useState("");
  const [settings, setSettings] = useState("");

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}teams/edit/${staffId}`, config)
        .then((response) => {
          //console.log(response.data);
          setFullName(response.data.fullName);
          setEduQualification(response.data.eduQualification);
          setDesignation(response.data.designation);
          setFacebook(response.data.facebookLink);
          setInstagram(response.data.instagramLink);
          setTwitter(response.data.twitterLink);
          setLinkedin(response.data.linkedinLink);
          setProfile(response.data.profilePicture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [staffId]);

  return (
    <>
      <section className="team padding view">
        <div className="container">
          <div className="subContainer">
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <div className="items shadow">
                <div className="img">
                  <img
                    src={`${API_URL}images/staff/${profile}`}
                    alt={fullName}
                  />
                  <div className="overlay">
                    <a
                      href={facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f icon"></i>
                    </a>
                    <a
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram icon"></i>
                    </a>
                    <a href={twitter} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter icon"></i>
                    </a>
                    <a
                      href={linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin icon"></i>
                    </a>
                  </div>
                </div>
                <div className="details">
                  <h2>{fullName}</h2>
                  <span>{eduQualification}</span>
                  <br />
                  {/* <span>{ val.phone }</span> */}
                  <p>{designation}</p>
                  <h3>{settings.title}</h3>
                </div>
              </div>
            )}
            <div className="submit-btn">
              <NavLink to={`${BASE_URL}admin/staff`}>Back</NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamView;
