import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./counter.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const EditCounter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { counterId } = useParams();
  const [title, setTitle] = useState("");
  const [totalNumber, setTotalNumber] = useState("");
  const [coverPicture, setCoverPicture] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}counters/edit/${counterId}`, config)
        .then((response) => {
          //console.log(response.data);
          setTitle(response.data.title);
          setTotalNumber(response.data.totalNumber);
          setCoverPicture(response.data.coverPicture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [counterId]);

  const UpdateCounterData = () => {
    try {
      setUpdatedLoading(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("totalNumber", totalNumber);

      // console.log(formData);
      if (coverPicture) {
        formData.append("coverPicture", coverPicture);
      } else {
        formData.append("coverPicture", coverPicture);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}counters/edit/${counterId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          alert("Counter Data Updated Successfully!");
          setTitle("");
          setTotalNumber("");
          setCoverPicture("");
          setUpdatedLoading(false);
          navigate("/admin/counters");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCounterHeading">Update Counter Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="counter-form-data">
              <div className="insert-counter-form">
                <div className="input-group">
                  <label htmlFor="">Counter Title *</label>
                  <input
                    type="text"
                    placeholder="Counter Title"
                    required
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Total Number *</label>
                  <input
                    type="text"
                    placeholder="Total number"
                    required
                    value={totalNumber}
                    onChange={(event) => {
                      setTotalNumber(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Cover Picture *</label>
                  <div className="editProfilePicture">
                    <input
                      type="file"
                      name="coverPicture"
                      onChange={(event) =>
                        setCoverPicture(event.target.files[0])
                      }
                      size="lg"
                    />
                    <img
                      src={`${API_URL}images/counters/${coverPicture}`}
                      alt={title}
                    />
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={UpdateCounterData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/counters`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCounter;
