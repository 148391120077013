import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./course.css";

// const API_URL = "http://localhost:3001/";

const CourseView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { category, slug } = useParams();
  const [title, setTitle] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [totalSubject, setTotalSubject] = useState("");
  const [totalMark, setTotalMark] = useState("");
  const [totalClass, setTotalClass] = useState("");
  const [admissionFee, setAdmissionFee] = useState("");
  const [monthlyFee, setMonthlyFee] = useState("");
  const [examNumber, setExamNumber] = useState("");
  const [examFee, setExamFee] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [courseSubject, setCourseSubject] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses/${category}/${slug}`, config)
        .then((response) => {
          //console.log(response.data);
          setTitle(response.data.title);
          setCourseDuration(response.data.courseDuration);
          setTotalSubject(response.data.totalSubject);
          setTotalMark(response.data.totalMark * response.data.totalSubject);
          setTotalClass(response.data.totalClass);
          setAdmissionFee(response.data.admissionFee);
          setMonthlyFee(response.data.monthlyFee);
          setExamNumber(response.data.examNumber);
          setExamFee(response.data.examFee);
          setTotalCost(
            response.data.admissionFee +
              response.data.monthlyFee * response.data.courseDuration +
              response.data.examNumber * response.data.examFee
          );
          setCourseSubject(response.data.courseSubject.split(","));
          setRequirements(response.data.courseRequirement.split(","));
          setMetaTitle(response.data.metaTitle);
          setMetaKeyword(response.data.metaKeyword);
          setMetaDescription(response.data.metaDescription);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [category, slug]);

  return (
    <>
      <section className="coursesCard view">
        <div className="container">
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="subContainer">
              <div className="courseMetaPart">
                <p>
                  <strong>Meta Title: </strong> {metaTitle}
                </p>
                <p>
                  <strong>Meta Keyword: </strong> {metaKeyword}
                </p>
                <p>
                  <strong>Meta Description: </strong> {metaDescription}
                </p>
              </div>
              <div className="coursePart">
                <div className="courseTitle">
                  <h2>{title}</h2>
                </div>
                <div className="items">
                  <div className="content">
                    <div className="text">
                      <div className="rate">
                        <h3>
                          <span>Subjects </span>
                          <span>Marks: {totalMark}</span>
                        </h3>
                      </div>
                      <div className="details">
                        <ul className="subjectList">
                          {courseSubject.map((subject, index) => (
                            <>
                              <li key={index}>{subject}</li>
                            </>
                          ))}
                        </ul>
                      </div>
                      <div className="rate">
                        <h3>Requirement</h3>
                      </div>
                      <div className="details">
                        <ul className="subjectList">
                          {requirements.map((require, index) => (
                            <>
                              <li key={index}>{require}</li>
                            </>
                          ))}
                        </ul>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>Course Duration </span>
                          <span>
                            {courseDuration < 12
                              ? `${courseDuration} Months`
                              : courseDuration < 24
                              ? `${Math.ceil(courseDuration / 12)} Year`
                              : `${Math.ceil(courseDuration / 12)} Years`}
                          </span>
                        </h3>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>Total Subjects </span>
                          <span>{totalSubject}</span>
                        </h3>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>Total Marks </span>
                          <span>{totalMark}</span>
                        </h3>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>Total Classes (3 x {totalClass})</span>
                          <span>{totalClass * 3} Hours</span>
                        </h3>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>Admission Fee </span>
                          <span>
                            <span className="priceSymbol">৳</span>
                            {admissionFee.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </h3>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>
                            Monthly Fee ({courseDuration}x{monthlyFee})
                          </span>
                          <span>
                            <span className="priceSymbol">৳</span>
                            {(courseDuration * monthlyFee).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </h3>
                      </div>
                      <div className="rate">
                        <h3>
                          <span>
                            Exam. Fee ({examNumber}x{examFee})
                          </span>
                          <span>
                            <span className="priceSymbol">৳</span>
                            {(examNumber * examFee).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="price">
                    <h3>
                      Total Course Fee: <span className="priceSymbol">৳</span>
                      {totalCost.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <NavLink to={`${BASE_URL}admin/courses`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CourseView;
