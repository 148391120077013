import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./counter.css";

// const API_URL = "http://localhost:3001/";

const Counters = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [counters, setCounters] = useState([]);
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}counters`)
        .then((response) => {
          //console.log(response.data);
          setCounters(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <section className="awrapper">
        <div className="container">
          <div className="subContainer grid">
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              counters.map((counter, index) => (
                <div className="box" key={index}>
                  <div className="img">
                    <img
                      src={`${API_URL}/images/counters/${counter.coverPicture}`}
                      alt={counter.title}
                    />
                  </div>
                  <div className="text">
                    <h2>{counter.totalNumber}</h2>
                    <h3>{counter.title}</h3>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Counters;
