import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./category.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const animatedComponents = makeAnimated();

const EditCatPosition = () => {
  const { catPositionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const [allWebsites, setAllWebsites] = useState([]);
  const [selectWebsite, setSelectWebsite] = useState(WEBSITE);
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [position, setPosition] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}setting`, config)
        .then((response) => {
          //console.log(response.data);
          setAllWebsites(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}categories`, config)
        .then((response) => {
          //console.log(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}cat-positions/edit/${catPositionId}`, config)
        .then((response) => {
          //console.log(response.data);
          setSelectCategory(response.data.category);
          setSelectWebsite(response.data.website);
          setPosition(response.data.position);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [catPositionId]);

  const updateData = () => {
    try {
      setUpdatedLoading(true);
      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      const data = {
        category: selectCategory,
        website: selectWebsite,
        position,
      };

      axios
        .put(`${API_URL}cat-positions/edit/${catPositionId}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(`This category is already exit!`);
          }
          //console.log(response.data);
          setPosition("");
          alert("Category Position Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/cat-positions");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCategoryHeading">Update Category</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="category-form-data">
              <div className="insert-category-form grid">
                <div className="input-group">
                  <label htmlFor="">Select Website *</label>
                  <Select
                    name="Website"
                    components={animatedComponents}
                    options={allWebsites
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((website, index) => {
                        return {
                          key: index,
                          value: website.title,
                          label: website.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectWebsite(event.value)}
                  />
                  <p>Select Option: {selectWebsite}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Main Category *</label>
                  <Select
                    name="Category"
                    components={animatedComponents}
                    options={categories.map((category, index) => {
                      return {
                        key: index,
                        value: category.title,
                        label: category.title,
                      };
                    })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectCategory(event.value)}
                  />
                  <p>Select Option: {selectCategory}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Position *</label>
                  <input
                    type="number"
                    placeholder="Positon Number"
                    required
                    value={position}
                    onChange={(event) => {
                      setPosition(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/cat-positions`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCatPosition;
