import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./apply.css";

// const API_URL = "http://localhost:3001/";

const options = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
  { value: "None", label: "None" },
];

const paymentOptions = [
  { value: "BKash", label: "BKash" },
  { value: "Rocket", label: "Rocket" },
  { value: "Bank", label: "Bank" },
  { value: "Cash", label: "Cash" },
];

const EditStudent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { studentId } = useParams();
  const [courses, setCourses] = useState([]);
  const [courseDuration, setCourseDuration] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [selectCourseTitle, setSelectCourseTitle] = useState("");
  const [selectCourseDuration, setSelectCourseDuration] = useState("");
  const [nameBangla, setNameBangla] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [fatherNameBangla, setFatherNameBangla] = useState("");
  const [fatherNameEnglish, setFatherNameEnglish] = useState("");
  const [motherNameBangla, setMotherNameBangla] = useState("");
  const [motherNameEnglish, setMotherNameEnglish] = useState("");
  const [presentAddress, setPresentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [selectRequirement, setSelectRequirement] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState("");
  const [blood, setBlood] = useState("");
  const [days, setDays] = useState("");
  const [shift, setShift] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [phone, setPhone] = useState("");
  const [studentPicture, setStudentPicture] = useState("");
  //   const [nidPicture, setNidPicture] = useState("");
  //   const [certificatePicture, setCertificatePicture] = useState("");

  //This is Select Options Animations
  const animatedComponents = makeAnimated();

  //For Navigate
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}applies/edit/${studentId}`, config)
        .then((response) => {
          //console.log(response.data);
          setSelectCourseTitle(response.data.courseTitle);
          setSelectCourseDuration(response.data.courseDuration);
          setNameBangla(response.data.nameBangla);
          setNameEnglish(response.data.nameEnglish);
          setFatherNameBangla(response.data.fnameBangla);
          setFatherNameEnglish(response.data.fnameEnglish);
          setMotherNameBangla(response.data.mnameBangla);
          setMotherNameEnglish(response.data.mnameEnglish);
          setPresentAddress(response.data.presentAddress);
          setPermanentAddress(response.data.permanentAddress);
          setSelectRequirement(response.data.eduQualification);
          setDob(response.data.dateOfBirth);
          setNationality(response.data.nationality);
          setBlood(response.data.blood);
          setDays(response.data.days);
          setShift(response.data.shift);
          setPaymentMethod(response.data.paymentMethod);
          setTransactionId(response.data.transactionId);
          setPhone(response.data.phone);
          setStudentPicture(response.data.profilePicture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [studentId]);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses`, config)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}course-duration`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseDuration(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}requires`, config)
        .then((response) => {
          //console.log(response.data);
          setRequirements(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      setUpdatedLoading(true);
      var formData = new FormData();
      formData.append("courseTitle", selectCourseTitle);
      formData.append("courseDuration", selectCourseDuration);
      formData.append("nameBangla", nameBangla);
      formData.append("nameEnglish", nameEnglish);
      formData.append("fnameBangla", fatherNameBangla);
      formData.append("fnameEnglish", fatherNameEnglish);
      formData.append("mnameBangla", motherNameBangla);
      formData.append("mnameEnglish", motherNameEnglish);
      formData.append("presentAddress", presentAddress);
      formData.append("permanentAddress", permanentAddress);
      formData.append("eduQualification", selectRequirement);
      formData.append("dateOfBirth", dob);
      formData.append("nationality", nationality);
      formData.append("blood", blood);
      formData.append("days", days);
      formData.append("shift", shift);
      formData.append("paymentMethod", paymentMethod);
      formData.append("transactionId", transactionId);
      formData.append("phone", phone);

      if (studentPicture) {
        formData.append("profilePicture", studentPicture);
      } else {
        formData.append("profilePicture", studentPicture);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}applies/update/${studentId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          alert("Student Data Updated Successfully!");
          setSelectCourseTitle("");
          setSelectCourseDuration("");
          setNameBangla("");
          setNameEnglish("");
          setFatherNameBangla("");
          setFatherNameEnglish("");
          setMotherNameBangla("");
          setMotherNameEnglish("");
          setPresentAddress("");
          setPermanentAddress("");
          setSelectRequirement("");
          setDob("");
          setNationality("");
          setBlood("");
          setDays("");
          setShift("");
          setPaymentMethod("");
          setTransactionId("");
          setPhone("");
          setStudentPicture("");
          event.target.reset();
          setUpdatedLoading(false);
          navigate(`/admin/students`);
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="InsertApplyHeading">
            <h1>Update Students Details</h1>
          </div>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              <div className="form-data">
                <div className="apply-form">
                  <div className="input-group">
                    <label htmlFor="">Course Title *</label>
                    <Select
                      components={animatedComponents}
                      options={[...courses]
                        .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map(function (course, index) {
                          return {
                            key: index,
                            value: course.title,
                            label: course.title,
                          };
                        })}
                      closeMenuOnSelect={true}
                      onChange={(event) => setSelectCourseTitle(event.value)}
                    />
                    <p>Selected Option: {selectCourseTitle}</p>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Course Duration *</label>
                    <Select
                      name="CourseDuration"
                      components={animatedComponents}
                      options={[...courseDuration]
                        .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map(function (duration, index) {
                          return {
                            key: index,
                            value: duration.title,
                            label: duration.title,
                          };
                        })}
                      closeMenuOnSelect={true}
                      onChange={(event) => setSelectCourseDuration(event.value)}
                    />
                    <p>Selected Option: {selectCourseDuration}</p>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Full Name (Bangla)*</label>
                    <input
                      type="text"
                      placeholder="Name (Bangla)"
                      required
                      value={nameBangla}
                      onChange={(event) => {
                        setNameBangla(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Full Name (English)*</label>
                    <input
                      type="text"
                      placeholder="Name (English)"
                      required
                      value={nameEnglish}
                      onChange={(event) => {
                        setNameEnglish(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Father Name (Bangla)*</label>
                    <input
                      type="text"
                      placeholder="Father name (Bangla)"
                      required
                      value={fatherNameBangla}
                      onChange={(event) => {
                        setFatherNameBangla(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Father Name (English)*</label>
                    <input
                      type="text"
                      placeholder="Father name (English)"
                      required
                      value={fatherNameEnglish}
                      onChange={(event) => {
                        setFatherNameEnglish(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Mother Name (Bangla)*</label>
                    <input
                      type="text"
                      placeholder="Mother name (Bangla)"
                      required
                      value={motherNameBangla}
                      onChange={(event) => {
                        setMotherNameBangla(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Mother Name (English)*</label>
                    <input
                      type="text"
                      placeholder="Mother name (English)"
                      required
                      value={motherNameEnglish}
                      onChange={(event) => {
                        setMotherNameEnglish(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Present Address *</label>
                    <textarea
                      value={presentAddress}
                      placeholder="Write Present Address"
                      rows={4}
                      onChange={(event) => {
                        setPresentAddress(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Permanent Address *</label>
                    <textarea
                      value={permanentAddress}
                      placeholder="Write Permanent Address"
                      rows={4}
                      onChange={(event) => {
                        setPermanentAddress(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Education Qualification *</label>
                    <Select
                      components={animatedComponents}
                      options={[...requirements]
                        .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map(function (course, index) {
                          return {
                            key: index,
                            value: course.title,
                            label: course.title,
                          };
                        })}
                      closeMenuOnSelect={true}
                      onChange={(event) => setSelectRequirement(event.value)}
                    />
                    <p>Selected Options: {selectRequirement}</p>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Date of birth *</label>
                    <input
                      type="date"
                      required
                      value={dob}
                      onChange={(event) => {
                        setDob(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Nationality*</label>
                    <input
                      type="text"
                      placeholder="Nationality (Bangladeshi)"
                      required
                      value={nationality}
                      onChange={(event) => {
                        setNationality(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Blood Group *</label>
                    <Select
                      components={animatedComponents}
                      options={options}
                      closeMenuOnSelect={true}
                      onChange={(event) => setBlood(event.value)}
                    />
                    <p>Selected Option: {blood}</p>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Select Day *</label>
                    <select
                      value={days}
                      onChange={(event) => setDays(event.target.value)}
                    >
                      <option>-Select-</option>
                      <option>Friday</option>
                      <option>Monday</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Select Shift *</label>
                    <select
                      value={shift}
                      onChange={(event) => setShift(event.target.value)}
                    >
                      <option>-Select-</option>
                      <option>Morning</option>
                      <option>Evening</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Select Payment Method *</label>
                    <Select
                      components={animatedComponents}
                      options={paymentOptions}
                      closeMenuOnSelect={true}
                      onChange={(event) => setPaymentMethod(event.value)}
                    />
                    <p>Selected Option: {paymentMethod}</p>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Transaction ID or MR Number*</label>
                    <input
                      type="text"
                      placeholder="Transaction ID (Bkash, Rocket, Bank, Cash)"
                      required
                      value={transactionId}
                      onChange={(event) => {
                        setTransactionId(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Contact Number*</label>
                    <input
                      type="text"
                      placeholder="Contact Number"
                      required
                      value={phone}
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Upload Picture *</label>
                    <div className="editProfilePicture">
                      <input
                        type="file"
                        name="studentPicture"
                        onChange={(event) =>
                          setStudentPicture(event.target.files[0])
                        }
                        size="lg"
                      />
                      <img
                        src={`${API_URL}images/applies/profile/${studentPicture}`}
                        alt={nameEnglish}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-btn">
                  <input
                    type="submit"
                    value={updatedLoading ? "Updating.." : "Update"}
                    disabled={updatedLoading}
                    className={updatedLoading ? "disableBtn" : null}
                  />
                  <NavLink to={`${BASE_URL}admin/students`}>Back</NavLink>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EditStudent;
