import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  if (localStorage.getItem("accessToken")) {
    return <Outlet />;
  } else {
    if (window.confirm("Your Token is Expired and Your Session is Expired")) {
      localStorage.removeItem("accessToken");
      return <Navigate to={`/admin/login`} />;
    }
  }
};

export default PrivateRoute;
