import axios from "axios";
import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../helpers/AuthContext";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./admin.css";
// const API_URL = "http://localhost:3001/";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const loginData = () => {
    try {
      setIsLoading(true);
      const data = {
        email: email,
        password: password,
      };
      axios.post(`${API_URL}admins/login`, data).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(response.data.error);
        }
        try {
          localStorage.setItem("accessToken", response.data.token);
          setAuthState({
            email: response.data.email,
            username: response.data.username,
            id: response.data.id,
            role: response.data.role,
            task: response.data.task,
            status: true,
          });
          const data2 = {
            email,
            token: localStorage.getItem("accessToken"),
          };

          const config = {
            headers: {
              "Content-Tyoe": "multipart/form-data",
            },
          };

          axios
            .put(`${API_URL}admins/set-token/${email}`, data2, config)
            .then((response) => {
              if (response.data.error) {
                setIsLoading(false);
                return alert(`You can't logout!`);
              }
              //console.log(response.data);
              // window.location.reload();
              setIsLoading(false);
              navigate("/admin/dashboard");
            });
        } catch (error) {
          alert(error.message);
          setIsLoading(false);
        }
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="loginBg">
        <div className="container">
          <div className="subContainer">
            {authState.status ? (
              navigate("/admin/dashboard")
            ) : (
              <div className="login-form-data">
                <h1 className="findAdminHeading">Admin Login</h1>
                <div className="login-insert-form">
                  <div className="input-group">
                    <label htmlFor="">E-mail *</label>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      required
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Password *</label>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      required
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    onClick={loginData}
                    disabled={isLoading}
                    className={isLoading ? "disableBtn" : null}
                  >
                    {isLoading ? "Login.." : "Login"}
                  </button>
                </div>
                <div className="forgot-password">
                  <span>
                    <NavLink to={`${BASE_URL}admin/forgot-password/${email}`}>
                      Forgot Password?
                    </NavLink>
                  </span>
                  <span>
                    <NavLink to={`${BASE_URL}admin/login`}>
                      Don't have an account? Contact Admin.
                    </NavLink>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
