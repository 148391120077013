import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./hero.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const Hero = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [catPositions, setCatPositions] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}cat-positions/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setCatPositions(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCatPositions]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}categories`)
        .then((response) => {
          //console.log(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCategories]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}courses`)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
          setCourseLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCourses]);

  return (
    <>
      <section className="hero">
        <div className="container ">
          <div className="subContainer">
            <div className="heroContainer">
              {isLoading ? (
                <div className="loadingSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                catPositions
                  .sort((a, b) => a.position - b.position)
                  .map((catPosition, index) => {
                    let count = 1;
                    return (
                      <>
                        <div
                          className={
                            count++ > 1
                              ? "courseProfile"
                              : "courseProfile courseOnlyOne"
                          }
                          key={index}
                        >
                          <div className="courseHeading">
                            <h2>{catPosition.category}</h2>
                          </div>
                          <div className="courseCategoryPicture">
                            {categories.map((category, index) =>
                              category.title === catPosition.category ? (
                                <img
                                  key={index}
                                  src={`${API_URL}images/categories/${category.coverPicture}`}
                                  alt={catPosition.title}
                                />
                              ) : null
                            )}
                          </div>
                          <div className="courseList">
                            {courseLoading ? (
                              <div className="loadingSpinner">
                                <img
                                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                                  alt="Loading.."
                                />
                              </div>
                            ) : (
                              courses
                                .sort(
                                  (a, b) => a.courseDuration - b.courseDuration
                                )
                                .map((course, index) =>
                                  course.category === catPosition.category ? (
                                    <NavLink
                                      to={`/${catPosition.category
                                        .toLowerCase()
                                        .split(" ")
                                        .join("-")}/${course.slug}`}
                                      className="is-active"
                                      key={index}
                                    >
                                      *{" "}
                                      {`${course.title} (${
                                        course.courseDuration < 12
                                          ? course.courseDuration + "M"
                                          : course.courseDuration / 12 + "Y"
                                      })`}
                                    </NavLink>
                                  ) : null
                                )
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="marigin"></div>
    </>
  );
};

export default Hero;
