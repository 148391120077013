import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./apply.css";

// const API_URL = "http://localhost:3001/";

const ViewStudent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appliesDetails, setAppliedDetails] = useState({});
  const [settings, setSettings] = useState("");
  const { studentId } = useParams();

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}applies/edit/${studentId}`, config)
        .then((response) => {
          //console.log(response.data);
          setAppliedDetails(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [studentId]);

  //This is use to print data
  const ref = useRef();

  return (
    <div className="downloadApply">
      <div className="DownloadApplyHeading">
        <h1>Your Application is Accepted!</h1>
        <h2>Please, Print or Download this document.</h2>
      </div>
      {isLoading ? (
        <div className="loadingSpinner">
          <img
            src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
            alt="Loading.."
          />
        </div>
      ) : (
        <div className="apply-details" ref={ref}>
          <div className="picture">
            <div className="institute">
              <img
                src={`${API_URL}images/website/${settings.logo}`}
                alt={`${settings.title}`}
              />

              <div>
                <h2>{settings.title}</h2>
                <p className="applied-course">
                  <strong>Course: </strong>
                  {appliesDetails.courseTitle}{" "}
                  {appliesDetails.selectCourseDuration}
                </p>
              </div>
            </div>
            <img
              src={`${API_URL}images/applies/profile/${appliesDetails.profilePicture}`}
              alt={appliesDetails.nameEnglish}
            />
          </div>
          <div className="apply-group">
            <h2>Course Title</h2>
            <span>:</span>
            <p>{appliesDetails.courseTitle}</p>
          </div>
          <div className="apply-group">
            <h2>Course Duration</h2>
            <span>:</span>
            <p>{appliesDetails.courseDuration}</p>
          </div>
          <div className="apply-group">
            <h2>Student Name</h2>
            <span>:</span>
            <p>
              {appliesDetails.nameBangla}, {appliesDetails.nameEnglish}
            </p>
          </div>
          <div className="apply-group">
            <h2>Father's Name</h2>
            <span>:</span>
            <p>
              {appliesDetails.fnameBangla}, {appliesDetails.fnameEnglish}
            </p>
          </div>
          <div className="apply-group">
            <h2>Mother's Name</h2>
            <span>:</span>
            <p>
              {appliesDetails.mnameBangla}, {appliesDetails.mnameEnglish}
            </p>
          </div>
          <div className="apply-group">
            <h2>Present Address</h2>
            <span>:</span>
            <p>{appliesDetails.presentAddress}</p>
          </div>
          <div className="apply-group">
            <h2>Permanent Address</h2>
            <span>:</span>
            <p>{appliesDetails.permanentAddress}</p>
          </div>
          <div className="apply-group">
            <h2>Edu. Qualification</h2>
            <span>:</span>
            <p>{appliesDetails.eduQualification}</p>
          </div>
          <div className="apply-group">
            <h2>Date of Birth</h2>
            <span>:</span>
            <p>{appliesDetails.dateOfBirth}</p>
          </div>
          <div className="apply-group">
            <h2>Nationality</h2>
            <span>:</span>
            <p>{appliesDetails.nationality}</p>
          </div>
          <div className="apply-group">
            <h2>Blood</h2>
            <span>:</span>
            <p>{appliesDetails.blood}</p>
          </div>
          <div className="apply-group">
            <h2>Day & Shift</h2>
            <span>:</span>
            <p>
              {appliesDetails.days}, {appliesDetails.shift}
            </p>
          </div>
          <div className="apply-group">
            <h2>Payment Method</h2>
            <span>:</span>
            <p>{appliesDetails.paymentMethod}</p>
          </div>
          <div className="apply-group">
            <h2>Transaction ID</h2>
            <span>:</span>
            <p>{appliesDetails.transactionId}</p>
          </div>
          <div className="apply-group">
            <h2>Phone Number</h2>
            <span>:</span>
            <p>{appliesDetails.phone}</p>
          </div>
        </div>
      )}
      <div className="printBtn">
        <ReactToPrint
          trigger={() => {
            return <button>Print Or Save Document</button>;
          }}
          content={() => ref.current}
          documentTitle={`Applied by ${appliesDetails.name} for ${appliesDetails.courseTitle} and ${appliesDetails.courseDuration} at ${settings.title}`}
          pageStyle="print"
        />
      </div>
      <div className="submit-btn">
        <NavLink to={`${BASE_URL}admin/students`}>Back</NavLink>
      </div>
    </div>
  );
};

export default ViewStudent;
