import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./faq.css";

// const API_URL = "http://localhost:3001/";

const EditFaq = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { faqId } = useParams();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}faqs/edit/${faqId}`, config)
        .then((response) => {
          //console.log(response.data);
          setQuestion(response.data.question);
          setAnswer(response.data.answer);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [faqId]);

  const updateData = () => {
    try {
      setUpdatedLoading(true);
      const data = {
        question,
        answer,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}faqs/edit/${faqId}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(`This FAQ is already exit!`);
          }
          //console.log(response.data);
          setQuestion("");
          setAnswer("");
          alert("FAQ Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/add-items");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findFaqHeading">Update FAQ</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="insert-form-data">
              <div className="insert-faq-form">
                <div className="input-group">
                  <label htmlFor="">Question*</label>
                  <input
                    type="text"
                    placeholder="FAQ Question"
                    required
                    value={question}
                    onChange={(event) => {
                      setQuestion(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Answer *</label>
                  <textarea
                    placeholder="FAQ Answer"
                    required
                    rows="5"
                    value={answer}
                    onChange={(event) => {
                      setAnswer(event.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/faqs`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditFaq;
