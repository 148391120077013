import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./category.css";

// const API_URL = "http://localhost:3001/";

const animatedComponents = makeAnimated();

const InsertCatPosition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allWebsites, setAllWebsites] = useState([]);
  const [selectWebsite, setSelectWebsite] = useState(WEBSITE);
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [position, setPosition] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}setting`, config)
        .then((response) => {
          //console.log(response.data);
          setAllWebsites(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}categories`, config)
        .then((response) => {
          //console.log(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const categoryPositionData = () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      const data = {
        category: selectCategory,
        website: selectWebsite,
        position,
      };

      axios.post(`${API_URL}cat-positions`, data, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(response.data.error);
        }
        //console.log(response.data);
        alert("Category Position Data Added Successfully!");
        setPosition("");
        setIsLoading(false);
        navigate("/admin/cat-positions");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCategoryHeading">Add New Category Position</h1>
          <div className="category-form-data">
            <div className="insert-category-form grid">
              <div className="input-group">
                <label htmlFor="">Select Website *</label>
                <Select
                  name="Website"
                  components={animatedComponents}
                  options={allWebsites
                    .sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map(function (website, index) {
                      return {
                        key: index,
                        value: website.title,
                        label: website.title,
                      };
                    })}
                  closeMenuOnSelect={true}
                  onChange={(event) => setSelectWebsite(event.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Main Category *</label>
                <Select
                  name="Category"
                  components={animatedComponents}
                  options={categories.map(function (category, index) {
                    return {
                      key: index,
                      value: category.title,
                      label: category.title,
                    };
                  })}
                  closeMenuOnSelect={true}
                  onChange={(event) => setSelectCategory(event.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Position *</label>
                <input
                  type="number"
                  placeholder="Position Number"
                  required
                  value={position}
                  onChange={(event) => {
                    setPosition(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={categoryPositionData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/cat-positions`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertCatPosition;
