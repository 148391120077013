import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./youtube.css";

// const API_URL = "http://localhost:3001/";

const animatedComponents = makeAnimated();

const InsertYoutubeForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mainCategories, setMainCategories] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [playlistTitle, setPlaylistTitle] = useState("");
  const [totalVideoNumber, setTotalVideoNumber] = useState("");
  const [playlistLink, setPlaylistLink] = useState("");
  const [youtubeThumbnail, setYoutubeThumbnail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}categories`, config)
        .then((response) => {
          //console.log(response.data);
          setMainCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setMainCategories]);

  const insertYoutubeData = () => {
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("mainCategory", mainCategory);
      formData.append("playlistTitle", playlistTitle);
      formData.append("totalVideoNumber", totalVideoNumber);
      formData.append("playlistLink", playlistLink);
      formData.append("thumbnailPicture", youtubeThumbnail);

      // console.log(formData);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}youtubes`, formData, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(response.data.error);
        }
        //console.log(response.data);
        alert("Youtube Playlist Added Successfully!");
        setMainCategory("");
        setPlaylistTitle("");
        setTotalVideoNumber("");
        setPlaylistLink("");
        setYoutubeThumbnail("");
        setIsLoading(false);
        navigate("/admin/youtube");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findYoutubeHeading">
            Add New YouTube Playlist Details
          </h1>
          <div className="youtube-form-data">
            <div className="insert-youtube-form">
              <div className="input-group">
                <label htmlFor="">Main Category *</label>
                <Select
                  components={animatedComponents}
                  options={[...mainCategories]
                    .sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map(function (category, index) {
                      return {
                        key: index,
                        value: category.title,
                        label: category.title,
                      };
                    })}
                  closeMenuOnSelect={true}
                  onChange={(event) => setMainCategory(event.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Playlist Title *</label>
                <input
                  type="text"
                  placeholder="Playlist Title"
                  required
                  value={playlistTitle}
                  onChange={(event) => {
                    setPlaylistTitle(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Total Video Number *</label>
                <input
                  type="text"
                  placeholder="Total playlist video number"
                  required
                  value={totalVideoNumber}
                  onChange={(event) => {
                    setTotalVideoNumber(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Playlist Link *</label>
                <input
                  type="text"
                  placeholder="Playlist Link"
                  required
                  value={playlistLink}
                  onChange={(event) => {
                    setPlaylistLink(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Playlist Thumbnail *</label>
                <input
                  type="file"
                  name="thumbnail"
                  onChange={(event) =>
                    setYoutubeThumbnail(event.target.files[0])
                  }
                  size="lg"
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={insertYoutubeData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/youtube`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertYoutubeForm;
