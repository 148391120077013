import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Back from "../common/back/Back";
import Title from "../common/title/Title";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import BlogCard from "./BlogCard";

const Blog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);
  return (
    <>
      {isLoading ? null : (
        <Helmet>
          <title>Blogs | {`${settings.metaTitle}`}</title>
          <link rel="canonical" href={`${BASE_URL}about`} />
          <meta name="description" content={`${settings.metaDescription}`} />
          <meta name="keywords" content={`${settings.metaKeyword}`} />
        </Helmet>
      )}
      <Back title="Blog Posts" />

      <div className="blogBg">
        <div className="container">
          <div className="subContainer">
            <div className="blogHeading">
              <Title
                subtitle="Our Blog"
                title={`All Blog of ${settings.title}`}
              />
            </div>
            <section className="blog">
              <div className="grid">
                <BlogCard />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
