import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import YoutubeCourse from "../allcourse/YoutubeCourse";
import Title from "../common/title/Title";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const HomeCourseDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [catPositions, setCatPositions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}cat-positions/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setCatPositions(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCatPositions]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}courses`)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
          setCourseLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCourses]);

  return (
    <>
      <div className="container">
        <section className="homeAbout">
          <div className="subContainer">
            <div>
              <Title
                subtitle="ALL COURSES"
                title="Choice your favorite Course"
              />
            </div>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              catPositions
                .sort((a, b) => a.position - b.position)
                .map((catPosition, index) => {
                  let count = 1;
                  return (
                    <>
                      <section className="coursesCard" key={index}>
                        <div className="allSpecificPart">
                          <Title title={catPosition.category} />
                        </div>
                        <div className="grid">
                          {courseLoading ? (
                            <div className="loadingSpinner">
                              <img
                                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                                alt="Loading.."
                              />
                            </div>
                          ) : (
                            courses
                              .sort(
                                (a, b) => a.courseDuration - b.courseDuration
                              )
                              .map((course, index) =>
                                catPosition.category === course.category ? (
                                  <>
                                    <div
                                      className={
                                        count++ > 1
                                          ? "coursePart"
                                          : "coursePart courseOnlyOne"
                                      }
                                      key={index}
                                    >
                                      <div className="courseTitle">
                                        <h2>{`${course.title} (${
                                          course.courseDuration < 12
                                            ? course.courseDuration + "Months"
                                            : course.courseDuration / 12 === 1
                                            ? "1Year"
                                            : course.courseDuration / 12 +
                                              "Years"
                                        })`}</h2>
                                      </div>
                                      <div className="items">
                                        <div className="text">
                                          <div className="rate">
                                            <h3>Requirement</h3>
                                          </div>
                                          <div className="details">
                                            <ul className="subjectList">
                                              {course.courseRequirement
                                                .split(",")
                                                .map((require, index) => (
                                                  <li key={index}>{require}</li>
                                                ))}
                                            </ul>
                                          </div>
                                          <div className="rate">
                                            <h3>
                                              <span>Total Subjects </span>
                                              <span>{course.totalSubject}</span>
                                            </h3>
                                          </div>
                                          <div className="rate">
                                            <h3>
                                              <span>Admission Fee </span>
                                              <span>
                                                <span className="priceSymbol">
                                                  ৳
                                                </span>
                                                {course.admissionFee.toLocaleString(
                                                  undefined,
                                                  {
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </span>
                                            </h3>
                                          </div>
                                          <div className="rate">
                                            <h3>
                                              <span>
                                                Monthly Fee (
                                                {course.courseDuration}x
                                                {course.monthlyFee})
                                              </span>
                                              <span>
                                                <span className="priceSymbol">
                                                  ৳
                                                </span>
                                                {(
                                                  course.courseDuration *
                                                  course.monthlyFee
                                                ).toLocaleString(undefined, {
                                                  maximumFractionDigits: 2,
                                                })}
                                              </span>
                                            </h3>
                                          </div>
                                          <div className="rate">
                                            <h3>
                                              <span>
                                                Exam. Fee ({course.examNumber}x
                                                {course.examFee})
                                              </span>
                                              <span>
                                                <span className="priceSymbol">
                                                  ৳
                                                </span>
                                                {(
                                                  course.examNumber *
                                                  course.examFee
                                                ).toLocaleString(undefined, {
                                                  maximumFractionDigits: 2,
                                                })}
                                              </span>
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="price">
                                          <h3>
                                            Total Course Fee:{" "}
                                            <span className="priceSymbol">
                                              ৳
                                            </span>
                                            {(
                                              course.admissionFee +
                                              course.monthlyFee *
                                                course.courseDuration +
                                              course.examFee * course.examNumber
                                            ).toLocaleString(undefined, {
                                              maximumFractionDigits: 2,
                                            })}{" "}
                                          </h3>
                                        </div>
                                        <div className="contact">
                                          <h4>{settings.phone}</h4>
                                        </div>
                                        <div className="more-details">
                                          <NavLink to={`${BASE_URL}apply`}>
                                            Apply Now
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null
                              )
                          )}
                        </div>
                        <YoutubeCourse category={catPosition.category} />
                      </section>
                    </>
                  );
                })
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default HomeCourseDetails;
