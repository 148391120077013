import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import CardNumber from "./CardNumber";
import { AuthContext } from "../../helpers/AuthContext";
import "./admin.css";
// const API_URL = "http://localhost:3001/";

const Dashboard = () => {
  const { authState } = useContext(AuthContext);
  const [studentCount, setStudentCount] = useState(0);
  const [batchCount, setBatchCount] = useState(0);
  const [blogCount, setBlogCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [courseCount, setCourseCount] = useState(0);
  const [courseDurationCount, setCourseDurationCount] = useState(0);
  const [courseSessionCount, setCourseSessionCount] = useState(0);
  const [requirementCount, setRequirementCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [subjectCount, setSubjectCount] = useState(0);
  const [teamCount, setTeamCount] = useState(0);
  const [testimonialCount, setTestimonialCount] = useState(0);
  const [youtubeCount, setYoutubeCount] = useState(0);
  const [featureCount, setFeatureCount] = useState(0);
  const [faqCount, setFaqCount] = useState(0);
  const [catPositionCount, setCatPositionCount] = useState(0);
  const [counterCount, setCounterCount] = useState(0);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}applies/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setStudentCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}batches/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setBatchCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}blogs/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setBlogCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}categories/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setCategoryCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}courses/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}course-duration/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseDurationCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}course-session/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseSessionCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}requires/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setRequirementCount(response.data);
          setRequirementCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}result/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setResultCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}subjects/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setSubjectCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}teams/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setTeamCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}testimonials/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setTestimonialCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}youtubes/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setYoutubeCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}features/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setFeatureCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}faqs/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setFaqCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}cat-positions/count/${WEBSITE}`, config)
        .then((response) => {
          //console.log(response.data);
          setCatPositionCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      axios
        .get(`${API_URL}counters/count/`, config)
        .then((response) => {
          //console.log(response.data);
          setCounterCount(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="dashboard">
            <div className="dashboard-header">
              <h2>Admin Dashboard</h2>

              <div>
                {authState.task.match(new RegExp(`(?:BasicSetting|All)`, "g")) ? (
                  <NavLink to={`${BASE_URL}admin/setting`}>
                    Basic Setting
                  </NavLink>
                ) : null}
                &nbsp;
                {authState.task.match(new RegExp(`(?:UserManage|All)`, "g")) ? (
                  <NavLink to={`${BASE_URL}admin/user-dashboard`}>
                    Users List
                  </NavLink>
                ) : null}
              </div>
            </div>
            <div className="dashboard-card">
              {authState.task.match(new RegExp(`(?:Students|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Students"}
                  cardValue={studentCount}
                  cardLink={`${BASE_URL}admin/students`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Batches|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Batches"}
                  cardValue={batchCount}
                  cardLink={`${BASE_URL}admin/batches`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Categories|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Categories"}
                  cardValue={categoryCount}
                  cardLink={`${BASE_URL}admin/categories`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Courses|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Courses"}
                  cardValue={courseCount}
                  cardLink={`${BASE_URL}admin/courses`}
                />
              ) : null}
              {authState.task.match(
                new RegExp(`(?:CourseDuration|All)`, "g")
              ) ? (
                <CardNumber
                  cardTitle={"Course Duration"}
                  cardValue={courseDurationCount}
                  cardLink={`${BASE_URL}admin/course-durations`}
                />
              ) : null}
              {authState.task.match(
                new RegExp(`(?:CourseSession|All)`, "g")
              ) ? (
                <CardNumber
                  cardTitle={"Course Session"}
                  cardValue={courseSessionCount}
                  cardLink={`${BASE_URL}admin/course-sessions`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Requirements|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Requirements"}
                  cardValue={requirementCount}
                  cardLink={`${BASE_URL}admin/requirements`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Subjects|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Subjects"}
                  cardValue={subjectCount}
                  cardLink={`${BASE_URL}admin/subjects`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Results|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Results"}
                  cardValue={resultCount}
                  cardLink={`${BASE_URL}admin/results`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Blogs|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Blogs"}
                  cardValue={blogCount}
                  cardLink={`${BASE_URL}admin/blogs`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Staff|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Staff"}
                  cardValue={teamCount}
                  cardLink={`${BASE_URL}admin/staff`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Reviews|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Reviews"}
                  cardValue={testimonialCount}
                  cardLink={`${BASE_URL}admin/testimonials`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Youtube|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"YouTubes"}
                  cardValue={youtubeCount}
                  cardLink={`${BASE_URL}admin/youtube`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Features|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Features"}
                  cardValue={featureCount}
                  cardLink={`${BASE_URL}admin/features`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:FAQs|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"FAQs"}
                  cardValue={faqCount}
                  cardLink={`${BASE_URL}admin/faqs`}
                />
              ) : null}
              {authState.task.match(
                new RegExp(`(?:CategoryPosition|All)`, "g")
              ) ? (
                <CardNumber
                  cardTitle={"Category Position"}
                  cardValue={catPositionCount}
                  cardLink={`${BASE_URL}admin/cat-positions`}
                />
              ) : null}
              {authState.task.match(new RegExp(`(?:Counters|All)`, "g")) ? (
                <CardNumber
                  cardTitle={"Counters"}
                  cardValue={counterCount}
                  cardLink={`${BASE_URL}admin/counters`}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
