import axios from "axios";
import React, { useEffect, useState } from "react";
import Title from "../common/title/Title";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./testimonial.css";

// const API_URL = "http://localhost:3001/";

const Test = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}testimonials`)
        .then((response) => {
          //console.log(response.data);
          setTestimonials(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <div className="container">
        <section className="testimonial">
          <div className="subContainer">
            <Title subtitle="TESTIMONIAL" title="Our Successful Students" />
            <div className="content grid2">
              {isLoading ? (
                <div className="loadingSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                testimonials
                  .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                  .map((testimonial, index) => (
                    <div
                      className={
                        testimonial.length > 1
                          ? "items shadow"
                          : "items shadow courseOnlyOne"
                      }
                      key={index}
                    >
                      <div className="box flex">
                        <div className="img">
                          <img
                            src={`${API_URL}images/testimonials/${testimonial.picture}`}
                            alt={testimonial.name}
                          />
                          <i className="fa fa-quote-left icon"></i>
                        </div>
                        <div className="name">
                          <h2>{testimonial.name}</h2>
                          <span>{testimonial.courseTitle}</span>
                          <h3>{settings.title}</h3>
                        </div>
                      </div>
                      <p>{testimonial.description}</p>
                    </div>
                  ))
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Test;
