import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./footer.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const Footer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [blogLoading, setBlogLoading] = useState(true);
  const maxLengthTitle = 70;
  const [settings, setSettings] = useState("");
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}blogs/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setBlogs(response.data);
          setBlogLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <hr />
      <footer>
        <div className="container">
          <div className="subContainer">
            <div className="grid3">
              {isLoading ? (
                <div className="loadingSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                <div className="box logo">
                  <div className="footer-logo-part">
                    <div className="footer-logo">
                      <NavLink to={`${BASE_URL}`}>
                        <img
                          src={`${API_URL}images/website/${settings.logo}`}
                          alt={settings.title}
                        />
                      </NavLink>
                    </div>
                    <div className="footer-title">
                      <h2>{settings.title}</h2>
                      <span>{settings.subTitle}</span>
                    </div>
                  </div>
                  <p>{settings.description}</p>
                  <div className="social-link">
                    <a
                      href={settings.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f icon"></i>
                    </a>
                    <a
                      href={settings.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram icon"></i>
                    </a>
                    <a
                      href={settings.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter icon"></i>
                    </a>
                    <a
                      href={settings.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin icon"></i>
                    </a>
                    <a
                      href={settings.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube icon"></i>
                    </a>
                  </div>
                </div>
              )}

              {blogLoading ? (
                <div className="loadingSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                <div className="box">
                  <h3>Recent Posts</h3>
                  <hr />
                  {blogs
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                    .slice(0, 3)
                    .map((blog, index) => (
                      <>
                        <div className="items" key={index}>
                          <NavLink to={`${BASE_URL}blogs/${blog.slug}`}>
                            <div className="img">
                              <img
                                src={`${API_URL}images/blogs/${blog.featureImage}`}
                                alt={blog.title}
                              />
                            </div>
                          </NavLink>
                          <div className="text">
                            <span>
                              <i className="fa fa-user"></i>
                              <label>{blog.category}</label>
                            </span>
                            <span>
                              <i className="fa fa-calendar-alt"></i>
                              <label>
                                <Moment format=" D-MMMM-YYYY">
                                  {blog.createdAt}
                                </Moment>
                              </label>
                            </span>
                            <NavLink to={`${BASE_URL}blogs/${blog.slug}`}>
                              <p>
                                {blog.title.length > maxLengthTitle
                                  ? blog.title.slice(0, maxLengthTitle) + "..."
                                  : blog.title}
                              </p>
                            </NavLink>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                </div>
              )}
              {isLoading ? (
                <div className="loadingSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                <div className="box last">
                  <h3>Have a Questions</h3>
                  <ul>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      <p>{settings.address}</p>
                    </li>
                    <li>
                      <i className="fa fa-phone-alt"></i>
                      <p>{settings.phone}</p>
                    </li>
                    <li>
                      <i className="fa fa-paper-plane"></i>
                      <p>{settings.email}</p>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </footer>
      <div className="legal">
        <div className="container">
          <div className="subContainer">
            <p>{settings.copyright}</p>
            <p className="developer">
              Development by :{" "}
              <a
                href="https://www.facebook.com/engrshahabuddin90"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shahabuddin
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
