import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const CourseTables = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [filterCourses, setFilterCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses`, config)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
          setFilterCourses(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCourses, setFilterCourses]);

  useEffect(() => {
    try {
      const filteredItems = courses.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.courseDuration.toLowerCase().includes(search.toLowerCase()) ||
          item.admissionFee.toString().includes(search) ||
          item.monthlyFee.toString().includes(search) ||
          item.examFee.toString().includes(search)
      );

      setFilterCourses(filteredItems);
    } catch (error) {
      alert(error.message);
    }
  }, [search, courses]);

  const deleteHandler = (deleteId) => {
    try {
      setDeletedLoading(true);
      const id = deleteId;
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .delete(`${API_URL}courses/delete/${id}`, config)
        .then((response) => {
          //console.log(response.data);
          setCourses(courses.filter((course) => course.id !== id));
          alert("Data Deleted Successfully!");
          setDeletedLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setDeletedLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setDeletedLoading(false);
    }
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => index + 1 + pageSize * (currentPage - 1),
      width: "70px",
    },
    {
      id: "courseTitle",
      name: "Course Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row, index) => (
        <div
          className="hover-cell"
          title={row.title}
          onMouseEnter={() => handleRowHover(index)}
          onMouseLeave={() => handleRowHover(null)}
        >
          {hoveredRowIndex === index ? row.title : truncateText(row.title, 20)}
        </div>
      ),
    },
    {
      name: "Course Duration",
      selector: (row) => row.courseDuration,
    },
    {
      name: "Admission Fee",
      selector: (row) => row.admissionFee,
    },
    {
      name: "Monthly Fee",
      selector: (row) => row.monthlyFee,
    },
    {
      name: "Exam. Fee",
      selector: (row) => row.examFee,
    },
    {
      name: "Added By",
      selector: (row) => row.Admin.username,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="updateButton">
          <NavLink
            to={`${BASE_URL}admin/${row.category.toLowerCase()}/${row.slug}`}
          >
            <img
              src={`${BASE_URL}images/basic-icons/view-icon.webp`}
              alt="View Icon"
            />
          </NavLink>
          <NavLink
            to={`${BASE_URL}admin/edit/${row.category.toLowerCase()}/${
              row.slug
            }`}
          >
            <img
              src={`${BASE_URL}images/basic-icons/edit-icon.png`}
              alt="Edit Icon"
            />
          </NavLink>
          <button
            onClick={() => deleteHandler(row.id)}
            disabled={deletedLoading}
            className={deletedLoading ? "disableBtn" : null}
          >
            {deletedLoading ? (
              <div className="countSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <img
                src={`${BASE_URL}images/basic-icons/delete-icon.png`}
                alt="Delete Icon"
              />
            )}
          </button>
        </div>
      ),
      width: "120px",
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <div className="container">
      <div className="subContainer">
        <div className="courseContainer">
          <div className="addButton">
            <h1>All Course Details</h1>
            <div className="tableBtn">
              <NavLink to={`${BASE_URL}admin/add-course`}>Add Course</NavLink>
              &nbsp;&nbsp;
              <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
            </div>
          </div>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={filterCourses.length >= 0 ? filterCourses : courses}
              pagination
              responsive
              striped
              fixedHeader
              fixedHeaderScrollHeight="450px"
              highlightOnHover
              subHeader
              subHeaderComponent={
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search Here"
                  onChange={(e) => setSearch(e.target.value)}
                />
              }
              defaultSortFieldId="courseTitle"
              defaultSortAsc={true}
              paginationPerPage={pageSize}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
              paginationTotalRows={filterCourses.length || courses.length}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setPageSize(currentRowsPerPage);
                setCurrentPage(currentPage);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseTables;
