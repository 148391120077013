import axios from "axios";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Moment from "react-moment";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./blog.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const BlogDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarLoading, setSidebarLoading] = useState(true);
  const maxLengthTitle = 55;
  const maxLengthMetaDesc = 98;
  const { blogSlug } = useParams();
  const [singleBlog, setSingleBlog] = useState("");
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}blogs/${blogSlug}`)
        .then((response) => {
          //console.log(response.data);
          setSingleBlog(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setIsLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  }, [blogSlug]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}blogs/basic/${WEBSITE}`)
        .then((response) => {
          // console.log(response.data);
          setBlogs(response.data);
          setSidebarLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setSidebarLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setSidebarLoading(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${singleBlog.title}`}</title>
        <link rel="canonical" href={`${BASE_URL}blogs/${blogSlug}`} />
        <meta name="description" content={`${singleBlog.metaDesc}`} />
        <meta name="keywords" content={`${singleBlog.keywords}`} />
      </Helmet>
      <div className="container">
        <div className="subContainer">
          <div className="blogDetail">
            <div className="left-side">
              <div className="recent-post-title">
                <h2>Recent Posts</h2>
              </div>
              <hr />
              <div className="recent-post-list">
                {sidebarLoading ? (
                  <div className="loadingSpinner">
                    <img
                      src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                      alt="Loading.."
                    />
                  </div>
                ) : (
                  blogs.map((blog, index) => (
                    <NavLink key={index} to={`/blogs/${blog.slug}`}>
                      {blog.title}
                    </NavLink>
                  ))
                )}
              </div>
            </div>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <div className="right-side">
                <div className="blog-feature-picture">
                  <img
                    src={`${API_URL}images/blogs/${singleBlog.featureImage}`}
                    alt={singleBlog.title}
                  />
                </div>
                <div className="blog-description">
                  <div className="blog-title">
                    <h2>{singleBlog.title}</h2>
                    <div className="blog-other">
                      <span>
                        <strong>Category:</strong>&nbsp;
                        {singleBlog.category}
                      </span>
                      &nbsp;&nbsp;
                      <span>
                        <strong>Date:</strong>&nbsp;
                        <Moment format=" D-MMMM-YYYY">
                          {singleBlog.createdAt}
                        </Moment>
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div
                    className="blog-desc"
                    dangerouslySetInnerHTML={{ __html: singleBlog.desc }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="related-blog">
          <div className="subContainer">
            <section className="blog">
              <div className="grid">
                {sidebarLoading ? (
                  <div className="loadingSpinner">
                    <img
                      src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                      alt="Loading.."
                    />
                  </div>
                ) : (
                  blogs
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                    .slice(0, 4)
                    .map((blog, index) => (
                      <div
                        className={
                          blogs.length > 1
                            ? "items shadow"
                            : "items shadow courseOnlyOne"
                        }
                        key={index}
                      >
                        <div className="img">
                          <img
                            src={`${API_URL}images/blogs/${blog.featureImage}`}
                            alt={blog.title}
                          />
                        </div>
                        <div className="text">
                          <div className="admin flexSB">
                            <span>
                              <i className="fa fa-user"></i>
                              <label htmlFor="">{blog.category}</label>
                            </span>
                            <span>
                              <i className="fa fa-calendar-alt"></i>
                              <label htmlFor="">
                                <Moment format=" D-MMMM-YYYY">
                                  {blog.createdAt}
                                </Moment>
                              </label>
                            </span>
                            {/* <span>
                      <i className="fa fa-comments"></i>
                      <label htmlFor="">{blog.comment}</label>
                    </span> */}
                          </div>
                          <h2>
                            {blog.title.length > maxLengthTitle
                              ? blog.title.slice(0, maxLengthTitle) + "..."
                              : blog.title}
                          </h2>
                          <p>
                            {blog.metaDesc.length > maxLengthMetaDesc
                              ? blog.metaDesc.slice(0, maxLengthMetaDesc) +
                                "..."
                              : blog.metaDesc}
                          </p>
                        </div>
                        <div className="more-details">
                          <NavLink to={`${BASE_URL}blogs/${blog.slug}`}>
                            Read More..
                          </NavLink>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
