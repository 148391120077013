import axios from "axios";
import React, { useEffect, useState } from "react";
import Title from "../common/title/Title";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./feature.css";

// const API_URL = "http://localhost:3001/";

const Features = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}features`)
        .then((response) => {
          //console.log(response.data);
          setFeatures(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <section className="featureHome">
        <div className="container">
          <div className="subContainer">
            <Title
              subtitle="LEARN ANYTHING"
              title={`${settings.title}'s Best Features`}
            />
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <div className="featureContainer">
                <div className="items">
                  {features.map((feature, index) => (
                    <div className="item" key={index}>
                      <div className="img">
                        <img
                          src={`${API_URL}images/features/${feature.picture}`}
                          alt={feature.title}
                        />
                      </div>
                      <div className="textFeature">
                        <h2>{feature.title}</h2>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
