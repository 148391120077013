import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import ResultForm from "./ResultFrom";

// const API_URL = "http://localhost:3001/";
const Result = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="loadingSpinner">
          <img
            src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
            alt="Loading.."
          />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Results | {`${settings.metaTitle}`}</title>
            <link rel="canonical" href={`${BASE_URL}results`} />
            <meta name="description" content={`${settings.metaDescription}`} />
            <meta name="keywords" content={`${settings.metaKeyword}`} />
          </Helmet>
          <h1 className="findResultHeading">Find Your Result</h1>
          <ResultForm />{" "}
        </>
      )}
    </>
  );
};

export default Result;
