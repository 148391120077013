import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./blog.css";

// const API_URL = "http://localhost:3001/";

const BlogView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { blogId } = useParams();
  const [mainCategory, setMainCategory] = useState("");
  const [website, setWebsite] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [featureImage, setFeatureImage] = useState("");

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}blogs/edit/${blogId}`, config)
        .then((response) => {
          //console.log(response.data);
          setMainCategory(response.data.category);
          setWebsite(response.data.website);
          setBlogTitle(response.data.title);
          setBlogDescription(response.data.desc);
          setMetaTitle(response.data.metaTitle);
          setMetaKeyword(response.data.keywords);
          setMetaDescription(response.data.metaDesc);
          setFeatureImage(response.data.featureImage);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [blogId]);

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findBlogHeading">View Blog Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <>
              <div className="blog-form-data">
                <div className="items shadow">
                  <div className="img">
                    <img
                      src={`${API_URL}images/blogs/${featureImage}`}
                      alt={blogTitle}
                    />
                  </div>
                  <div className="text">
                    <hr />
                    <p>
                      <strong>Selected Website: </strong>
                      {website}
                    </p>
                    <hr />
                    <p>
                      <strong>Blog Category: </strong>
                      {mainCategory}
                    </p>
                    <hr />
                    <p>
                      <strong>Blog Title: </strong>
                      {blogTitle}
                    </p>
                    <hr />
                    <p>
                      <strong>Meta Title: </strong>
                      {metaTitle}
                    </p>
                    <hr />
                    <p>
                      <strong>Keywords: </strong>
                      {metaKeyword}
                    </p>
                    <hr />
                    <p>
                      <strong>Meta Description: </strong>
                      {metaDescription}
                    </p>
                    <hr />
                    <p>
                      <strong>Blog Description: </strong>
                      <div
                        dangerouslySetInnerHTML={{ __html: blogDescription }}
                      />
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <NavLink to={`${BASE_URL}admin/blogs`}>Back</NavLink>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogView;
