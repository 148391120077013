import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import Title from "../common/title/Title";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const HBlog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const maxLengthTitle = 55;
  const maxLengthMetaDesc = 98;
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}blogs/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setBlogs(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <section className="blog homeBlog">
        <div className="container">
          <div className="subContainer">
            <Title subtitle="Our Blog" title="Recent From Blog" />
            <div className="grid">
              {isLoading ? (
                <div className="loadingSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                blogs
                  .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                  .slice(0, 5)
                  .map((blog, index) => (
                    <div
                      className={
                        blogs.length > 1
                          ? "items shadow"
                          : "items shadow courseOnlyOne"
                      }
                      key={index}
                    >
                      <div className="img">
                        <img
                          src={`${API_URL}images/blogs/${blog.featureImage}`}
                          alt={blog.title}
                        />
                      </div>
                      <div className="text">
                        <div className="admin flexSB">
                          <span>
                            <i className="fa fa-user"></i>
                            <label htmlFor="">{blog.category}</label>
                          </span>
                          <span>
                            <i className="fa fa-calendar-alt"></i>
                            <label htmlFor="">
                              <Moment format=" D-MMMM-YYYY">
                                {blog.createdAt}
                              </Moment>
                            </label>
                          </span>
                          {/* <span>
                      <i className="fa fa-comments"></i>
                      <label htmlFor="">{blog.comment}</label>
                    </span> */}
                        </div>
                        <h2>
                          {blog.title.length > maxLengthTitle
                            ? blog.title.slice(0, maxLengthTitle) + "..."
                            : blog.title}
                        </h2>
                        <p>
                          {blog.metaDesc.length > maxLengthMetaDesc
                            ? blog.metaDesc.slice(0, maxLengthMetaDesc) + "..."
                            : blog.metaDesc}
                        </p>
                      </div>
                      <div className="more-details">
                        <NavLink to={`${BASE_URL}blogs/${blog.slug}`}>
                          Read More..
                        </NavLink>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HBlog;
