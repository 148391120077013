import axios from "axios";
import React, { useEffect, useState } from "react";
import Title from "../common/title/Title";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./faq.css";

// const API_URL = "http://localhost:3001/";

const Faq = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [click, setClick] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const toggle = (index) => {
    if (click === index) {
      return setClick(null);
    }
    setClick(index);
  };

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}faqs`)
        .then((response) => {
          //console.log(response.data);
          setFaqs(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <section className="faqSection">
        <Title subtitle="Faq" title="Frequesntly Ask Questions" />
        {isLoading ? (
          <div className="loadingSpinner">
            <img
              src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
              alt="Loading.."
            />
          </div>
        ) : (
          <div className="faq">
            {faqs.map((faq, index) => (
              <div className="box" key={index}>
                <button
                  className="accordion"
                  onClick={() => toggle(index)}
                  key={index}
                >
                  <h2>{faq.question}</h2>
                  <span>
                    {click === index ? (
                      <i className="fa fa-chevron-down"></i>
                    ) : (
                      <i className="fa fa-chevron-right"></i>
                    )}
                  </span>
                </button>
                {click === index ? (
                  <div className="text">
                    <p>{faq.answer}</p>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </section>
    </>
  );
};

export default Faq;
