import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./blog.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const BlogCard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const maxLengthTitle = 55;
  const maxLengthMetaDesc = 98;
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}blogs/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setBlogs(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="container">
          <div className="subContainer">
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          </div>
        </div>
      ) : (
        blogs
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map((blog, index) => (
            <div
              className={
                blogs.length > 1 ? "items shadow" : "items shadow courseOnlyOne"
              }
              key={index}
            >
              <div className="img">
                <img
                  src={`${API_URL}images/blogs/${blog.featureImage}`}
                  alt={blog.title}
                />
              </div>
              <div className="text">
                <div className="admin flexSB">
                  <span>
                    <i className="fa fa-user"></i>
                    <label htmlFor="">{blog.category}</label>
                  </span>
                  <span>
                    <i className="fa fa-calendar-alt"></i>
                    <label htmlFor="">
                      <Moment format=" D-MMMM-YYYY">{blog.createdAt}</Moment>
                    </label>
                  </span>
                  {/* <span>
                      <i className="fa fa-comments"></i>
                      <label htmlFor="">{blog.comment}</label>
                    </span> */}
                </div>
                <h2>
                  {blog.title.length > maxLengthTitle
                    ? blog.title.slice(0, maxLengthTitle) + "..."
                    : blog.title}
                </h2>
                <p>
                  {blog.metaDesc.length > maxLengthMetaDesc
                    ? blog.metaDesc.slice(0, maxLengthMetaDesc) + "..."
                    : blog.metaDesc}
                </p>
              </div>
              <div className="more-details">
                <NavLink to={`/blogs/${blog.slug}`}>Read More..</NavLink>
              </div>
            </div>
          ))
      )}
    </>
  );
};

export default BlogCard;
