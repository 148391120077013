import { useEffect } from "react";

const isTokenExpired = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return true;
  }

  const decodedToken = decodeToken(token);
  const expirationTime = decodedToken.exp * 1000;
  const currentTime = Date.now();
  return currentTime > expirationTime;
};

const decodeToken = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

const AutoLogout = () => {
  const autoLogoutAndDeleteToken = () => {
    if (isTokenExpired()) {
      localStorage.removeItem("accessToken");
    }
  };

  useEffect(() => {
    const interval = setInterval(autoLogoutAndDeleteToken, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return null;
};

export default AutoLogout;
