import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./youtube.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const YoutubeView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { youtubeId } = useParams();
  const [category, setCategory] = useState("");
  const [playlistTitle, setPlaylistTitle] = useState("");
  const [videoNumber, setVideoNumber] = useState("");
  const [playlistLink, setPlaylistLink] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}youtubes/edit/${youtubeId}`, config)
        .then((response) => {
          //console.log(response.data);
          setCategory(response.data.mainCategory);
          setPlaylistTitle(response.data.playlistTitle);
          setVideoNumber(response.data.totalVideoNumber);
          setPlaylistLink(response.data.playlistLink);
          setThumbnail(response.data.thumbnailPicture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [youtubeId]);

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findBlogHeading">View Youtube Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="blog-form-data">
              <div className="items shadow">
                <div className="img">
                  <img
                    src={`${API_URL}images/youtubes/${thumbnail}`}
                    alt={playlistTitle}
                  />
                </div>
                <div className="text">
                  <hr />
                  <p>
                    <strong>Category: </strong>
                    {category}
                  </p>
                  <hr />
                  <p>
                    <strong>Playlist Title: </strong>
                    {playlistTitle}
                  </p>
                  <hr />
                  <p>
                    <strong>Playlist Link: </strong>
                    {playlistLink}
                  </p>
                  <hr />
                  <p>
                    <strong>Total Video Number: </strong>
                    {videoNumber}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          )}
          <div className="submit-btn">
            <NavLink to={`${BASE_URL}admin/youtube`}>Back</NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default YoutubeView;
