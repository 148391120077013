import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import About from "./components/about/About";
import Header from "./components/common/heading/Header";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";

import Login from "./components/admin";
import Dashboard from "./components/admin/Dashboard";
import ForgotPassword from "./components/admin/ForgotPassword";

import EditRequirement from "./components/allrequires/EditRequirement";
import InsertRequirement from "./components/allrequires/InsertRequirement";

import EditSubject from "./components/allsubjects/EditSubject";
import InsertSubjects from "./components/allsubjects/InsertSubjects";

import EditBatch from "./components/batches/EditBatch";
import InsertBatch from "./components/batches/InsertBatch";

import EditCategory from "./components/categories/EditCategory";
import InsertCategory from "./components/categories/InsertCategory";

import EditDuration from "./components/courseDuration/EditDuration";
import InsertCourseDuration from "./components/courseDuration/InsertCourseDuration";

import EditSession from "./components/courseSession/EditSession";
import InsertCourseSession from "./components/courseSession/InsertCourseSession";

import ApplyDownload from "./components/students/ApplyDownload";
import ApplyForm from "./components/students/ApplyForm";
import EditStudent from "./components/students/EditStudent";
import InsertStudent from "./components/students/InsertStudent";
import ViewStudent from "./components/students/ViewStudent";

import CourseHome from "./components/allcourse/CourseHome";
import CourseTables from "./components/allcourse/CourseTables";
import CourseView from "./components/allcourse/CourseView";
import EditCourse from "./components/allcourse/EditCourse";
import InsertCourses from "./components/allcourse/InsertCourses";

import EditResult from "./components/result/EditResult";
import InsertResult from "./components/result/InsertResultForm";
import Result from "./components/result/Result";
import ResultTables from "./components/result/ResultTables";
import ResultView from "./components/result/ResultView";

import Blog from "./components/blog/Blog";
import BlogTable from "./components/blog/BlogTable";
import BlogView from "./components/blog/BlogView";
import EditBlog from "./components/blog/EditBlog";
import InsertBlogsForm from "./components/blog/InsertBlogsForm";

import EditStaff from "./components/staff/EditStaff";
import InsertStaffForm from "./components/staff/InsertStaffForm";
import Staff from "./components/staff/Staff";
import StaffTable from "./components/staff/StaffTable";
import StaffView from "./components/staff/StaffView";

import EditYoutube from "./components/youtube/EditYoutube";
import InsertYoutubeForm from "./components/youtube/InsertYoutubeForm";
import YoutubeTable from "./components/youtube/YoutubeTable";
import YoutubeView from "./components/youtube/YoutubeView";

import EditTestimonial from "./components/testimonials/EditTestimonial";
import InsertTestimonial from "./components/testimonials/InsertTestimonial";
import TestimonialTable from "./components/testimonials/TestimonialTable";
import TestimonialView from "./components/testimonials/TestimonialView";

import { AuthContext } from "./helpers/AuthContext";

import CounterBoard from "./components/counter/CounterBoard";
import EditCounter from "./components/counter/EditCounter";
import InsertCounter from "./components/counter/InsertCounter";

import EditFaq from "./components/faq/EditFaq";
import FaqBoard from "./components/faq/FaqBoard";
import InsertFaq from "./components/faq/InsertFaq";

import axios from "axios";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import AddUsers from "./components/admin/AddUsers";
import AutoLogout from "./components/admin/AutoLogout";
import EditUsers from "./components/admin/EditUsers";
import PrivateRoute from "./components/admin/PrivateRoute";
import Setting from "./components/admin/Setting";
import UserDashboard from "./components/admin/UserDashboard";
import CourseDetails from "./components/allcourse/CourseDetails";
import RequirementBoard from "./components/allrequires/RequirementBoard";
import SubjectBoard from "./components/allsubjects/SubjectBoard";
import BatchBoard from "./components/batches/BatchBoard";
import BlogDetails from "./components/blog/BlogDetails";
import CategoryBoard from "./components/categories/CategoryBoard";
import CatPositionBoard from "./components/catpositions/CatPositionBoard";
import EditCatPosition from "./components/catpositions/EditCatPosition";
import InsertCatPosition from "./components/catpositions/InsertCatPosition";
import BottomToTopButton from "./components/common/topButton/BottomToTopButton ";
import { API_URL } from "./components/constants/Constants";
import DurationBoard from "./components/courseDuration/DurationBoard";
import SessionBoard from "./components/courseSession/SessionBoard";
import EditFeature from "./components/features/EditFeature";
import FeatureBoard from "./components/features/FeatureBoard";
import InsertFeature from "./components/features/InsertFeatures";
import StudentsBoard from "./components/students/StudentsBoard";

function App() {
  // const [loggedToken, setLoggedToken] = useState(localStorage.getItem("accessToken"));
  const [authState, setAuthState] = useState({
    username: "",
    email: "",
    id: 0,
    role: "",
    task: "",
    status: false,
  });
  
  useEffect(() => {
    const checkAuth = async () => {
      if (!authState.status && localStorage.getItem("accessToken")) {
        try {
          const response = await axios.get(`${API_URL}admins/auth`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          });

          if (response.data.error) {
            setAuthState({
              ...authState,
              status: false,
            });
          } else {
            setAuthState({
              username: response.data.username,
              email: response.data.email,
              id: response.data.id,
              role: response.data.role,
              task: response.data.task,
              status: true,
            });
          }
        } catch (error) {
          console.error("Error checking authentication:", error);
        }
      }
    };

    checkAuth();
  }, [authState]);

  return (
    <>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/:category/:slug" element={<CourseDetails />} />
            <Route path="/courses" element={<CourseHome />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blogs/:blogSlug" element={<BlogDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/result" element={<Result />} />
            <Route path="/apply" element={<ApplyForm />} />
            <Route
              path="/apply/download/:transactionId"
              element={<ApplyDownload />}
            />

            {/* admin routes */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/login/:tokenError" element={<Login />} />
            <Route
              path="/admin/forgot-password/:userEmail"
              element={<ForgotPassword />}
            />
            <Route path="/admin" element={<PrivateRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="setting" element={<Setting />} />

              <Route path="user-dashboard" element={<UserDashboard />} />
              <Route path="add-user" element={<AddUsers />} />
              <Route path="edit-user/:userID" element={<EditUsers />} />

              <Route path="students" element={<StudentsBoard />} />
              <Route path="add-student" element={<InsertStudent />} />
              <Route path="view-student/:studentId" element={<ViewStudent />} />
              <Route path="edit-student/:studentId" element={<EditStudent />} />

              <Route path="categories" element={<CategoryBoard />} />
              <Route path="add-category" element={<InsertCategory />} />
              <Route
                path="edit-category/:categoryId"
                element={<EditCategory />}
              />

              <Route path="cat-positions" element={<CatPositionBoard />} />
              <Route path="add-cat-position" element={<InsertCatPosition />} />
              <Route
                path="edit-cat-position/:catPositionId"
                element={<EditCatPosition />}
              />

              <Route path="requirements" element={<RequirementBoard />} />
              <Route path="add-requirement" element={<InsertRequirement />} />
              <Route
                path="edit-requirement/:requirementId"
                element={<EditRequirement />}
              />

              <Route path="batches" element={<BatchBoard />} />
              <Route path="add-batch" element={<InsertBatch />} />
              <Route path="edit-batch/:batchId" element={<EditBatch />} />

              <Route path="course-durations" element={<DurationBoard />} />
              <Route path="add-duration" element={<InsertCourseDuration />} />
              <Route
                path="edit-duration/:durationId"
                element={<EditDuration />}
              />

              <Route path="course-sessions" element={<SessionBoard />} />
              <Route path="add-session" element={<InsertCourseSession />} />
              <Route path="edit-session/:sessionId" element={<EditSession />} />

              <Route path="subjects" element={<SubjectBoard />} />
              <Route path="add-subject" element={<InsertSubjects />} />
              <Route path="edit-subject/:subjectId" element={<EditSubject />} />

              <Route path="courses" element={<CourseTables />} />
              <Route path="add-course" element={<InsertCourses />} />
              <Route path="edit/:category/:slug" element={<EditCourse />} />
              <Route path=":category/:slug" element={<CourseView />} />

              <Route path="results" element={<ResultTables />} />
              <Route path="add-result" element={<InsertResult />} />
              <Route path="edit-result/:resultId" element={<EditResult />} />
              <Route path="view-result/:resultId" element={<ResultView />} />

              <Route path="blogs" element={<BlogTable />} />
              <Route path="add-blog" element={<InsertBlogsForm />} />
              <Route path="edit-blog/:blogId" element={<EditBlog />} />
              <Route path="view-blog/:blogId" element={<BlogView />} />

              <Route path="staff" element={<StaffTable />} />
              <Route path="add-staff" element={<InsertStaffForm />} />
              <Route path="edit-staff/:staffId" element={<EditStaff />} />
              <Route path="view-staff/:staffId" element={<StaffView />} />

              <Route path="youtube" element={<YoutubeTable />} />
              <Route path="add-youtube" element={<InsertYoutubeForm />} />
              <Route path="edit-youtube/:youtubeId" element={<EditYoutube />} />
              <Route path="view-youtube/:youtubeId" element={<YoutubeView />} />

              <Route path="testimonials" element={<TestimonialTable />} />
              <Route path="add-testimonial" element={<InsertTestimonial />} />
              <Route
                path="edit-testimonial/:testimonialId"
                element={<EditTestimonial />}
              />
              <Route
                path="view-testimonial/:testimonialId"
                element={<TestimonialView />}
              />

              <Route path="counters" element={<CounterBoard />} />
              <Route path="add-counter" element={<InsertCounter />} />
              <Route path="edit-counter/:counterId" element={<EditCounter />} />

              <Route path="faqs" element={<FaqBoard />} />
              <Route path="add-faq" element={<InsertFaq />} />
              <Route path="edit-faq/:faqId" element={<EditFaq />} />

              <Route path="features" element={<FeatureBoard />} />
              <Route path="add-feature" element={<InsertFeature />} />
              <Route path="edit-feature/:featureId" element={<EditFeature />} />

              <Route path="*" element={<ErrorPage />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </Router>
      </AuthContext.Provider>
      <BottomToTopButton />
      <AutoLogout />
    </>
  );
}

export default App;
