import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../helpers/AuthContext";
import { API_URL, BASE_URL } from "../constants/Constants";

//const API_URL = "http://localhost:3001/";

const UserDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}admins`, config)
        .then((response) => {
          //console.log(response.data);
          setUsers(response.data);
          setFilterUsers(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setUsers, setFilterUsers]);

  useEffect(() => {
    try {
      const filteredItems = users.filter(
        (item) =>
          item.fullName.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.role.toLowerCase().includes(search.toLowerCase())
      );

      setFilterUsers(filteredItems);
    } catch (error) {
      alert(error.message);
    }
  }, [search, users]);

  const changeStatus = (changeId, status) => {
    try {
      setStatusLoading(true);
      if (
        window.confirm(
          "Are you sure to change this user status? If you change status active to inactive, User can not login."
        )
      ) {
        if (status > 0) {
          status = 0;
        } else {
          status = 1;
        }
        const data = {
          status,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        };
        axios
          .put(`${API_URL}admins/edit-status/${changeId}`, data, config)
          .then((response) => {
            if (response.data.error) {
              setStatusLoading(false);
              return alert(response.data.error);
            }
            setStatusLoading(false);
            alert("User Status Change Successfully!");
            window.location.reload();
          });
      }
    } catch (error) {
      setStatusLoading(false);
      alert(error.message);
    }
  };

  const deleteHandler = (deleteId, deleted) => {
    try {
      setDeleteLoading(true);
      if (
        window.confirm(
          "Be carefull! Are you sure to DELETE this user? If you delete this your details. You can't able to return this user. To return this user contact with developer."
        )
      ) {
        if (deleted > 0) {
          deleted = 0;
        } else {
          deleted = 1;
        }
        const data = {
          deleted,
        };
        const config = {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        };

        axios
          .put(`${API_URL}admins/edit-deleted/${deleteId}`, data, config)
          .then((response) => {
            //console.log(response.data);
            setUsers(users.filter((user) => user.id !== deleteId));
            alert("Data Deleted Successfully!");
            setDeleteLoading(false);
            window.location.reload();
          })
          .catch((error) => {
            alert(error.message);
            setDeleteLoading(false);
          });
      } else {
        alert(
          "Thanks, You have protect to login by changing status => Active to InActive"
        );
      }
    } catch (error) {
      alert(error.message);
      setDeleteLoading(false);
    }
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  let columns = [];
  if (authState.role === "Admin") {
    columns = [
      {
        name: "S. No",
        cell: (row, index) => index + 1 + pageSize * (currentPage - 1),
        width: "65px",
      },
      {
        id: "FullName",
        name: "Full Name",
        selector: (row) => row.fullName,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
      },
      {
        name: "Role",
        selector: (row) => row.role,
      },
      {
        id: "Assigned",
        name: "Assigned",
        selector: (row) => row.task,
        cell: (row, index) => (
          <div
            className="hover-cell itemsTag"
            title={row.task}
            onMouseEnter={() => handleRowHover(index)}
            onMouseLeave={() => handleRowHover(null)}
          >
            {hoveredRowIndex === index ? (
              row.task.split(",").map((item, i) => (
                <span key={i} className="tag">
                  {item}
                </span>
              ))
            ) : (
              truncateText(row.task, 20)
            )}
          </div>
        ),
      },
      {
        name: "Status",
        cell: (row) => (
          <div className="statusButton">
            <button
              onClick={() => changeStatus(row.id, row.status)}
              disabled={statusLoading}
              className={
                statusLoading
                  ? "disableBtn"
                  : row.status === 1
                  ? "active"
                  : "inactive"
              }
            >
              {statusLoading ? (
                <div className="countSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : row.status === 1 ? (
                "Active"
              ) : (
                "InActive"
              )}
            </button>
          </div>
        ),
      },
      {
        name: "Action",
        cell: (row) => (
          <div className="updateButton">
            <NavLink to={`/admin/edit-user/${row.id}`}>
              <img src="../images/basic-icons/edit-icon.png" alt="Edit Icon" />
            </NavLink>
            <button
              onClick={() => deleteHandler(row.id, row.deleted)}
              disabled={deleteLoading}
              className={deleteLoading ? "disableBtn" : null}
            >
              {deleteLoading ? (
                <div className="countSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                <img
                  src="../images/basic-icons/delete-icon.png"
                  alt="Delete Icon"
                />
              )}
            </button>
          </div>
        ),
        width: "120px",
      },
    ];
  } else {
    columns = [
      {
        name: "S. No",
        cell: (row, index) => index + 1,
        width: "65px",
      },
      {
        id: "FullName",
        name: "Full Name",
        selector: (row) => row.fullName,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
      },
      {
        name: "Role",
        selector: (row) => row.role,
      },
    ];
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <div className="container">
      <div className="subContainer">
        <div className="courseContainer">
          <div className="addButton">
            <h1>All User Details</h1>
            {authState.role === "Admin" ? (
              <div className="tableBtn">
                <NavLink to={`${BASE_URL}admin/add-user`}>Add User</NavLink>
                &nbsp;&nbsp;
                <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
              </div>
            ) : (
              <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
            )}
          </div>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={filterUsers.length >= 0 ? filterUsers : users}
              pagination
              responsive
              striped
              fixedHeader
              fixedHeaderScrollHeight="450px"
              highlightOnHover
              subHeader
              subHeaderComponent={
                <input
                  type="text"
                  className="searchInput"
                  placeholder="Search Here"
                  onChange={(e) => setSearch(e.target.value)}
                />
              }
              defaultSortFieldId="FullName"
              defaultSortAsc={true}
              paginationPerPage={pageSize}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
              paginationTotalRows={filterUsers.length || users.length}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setPageSize(currentRowsPerPage);
                setCurrentPage(currentPage);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
