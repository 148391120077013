import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./staff.css";

// const API_URL = "http://localhost:3001/";

const EditTeam = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { staffId } = useParams();
  const [fullName, setFullName] = useState("");
  const [eduQualification, setEduQualification] = useState("");
  const [designation, setDesignation] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [profile, setProfile] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}teams/edit/${staffId}`, config)
        .then((response) => {
          //console.log(response.data);
          setFullName(response.data.fullName);
          setEduQualification(response.data.eduQualification);
          setDesignation(response.data.designation);
          setFacebook(response.data.facebookLink);
          setInstagram(response.data.instagramLink);
          setTwitter(response.data.twitterLink);
          setLinkedin(response.data.linkedinLink);
          setProfile(response.data.profilePicture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [staffId]);

  const updateTeamData = () => {
    try {
      setUpdatedLoading(true);
      var formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("eduQualification", eduQualification);
      formData.append("designation", designation);
      formData.append("facebookLink", facebook);
      formData.append("instagramLink", instagram);
      formData.append("twitterLink", twitter);
      formData.append("linkedinLink", linkedin);

      if (profile) {
        formData.append("profilePicture", profile);
      } else {
        formData.append("profilePicture", profile);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}teams/edit/${staffId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(`This team member is already exit!`);
          }
          //console.log(response.data);
          setFullName("");
          setEduQualification("");
          setDesignation("");
          setFacebook("");
          setInstagram("");
          setTwitter("");
          setLinkedin("");
          setProfile("");
          alert("Team Data Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/staff");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findTeamHeading">Edit Staff/Doctors Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="team-form-data">
              <div className="insert-team-form">
                <div className="input-group">
                  <label htmlFor="">Full Name *</label>
                  <input
                    type="text"
                    placeholder="Full name"
                    required
                    value={fullName}
                    onChange={(event) => {
                      setFullName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Education Qualification *</label>
                  <input
                    type="text"
                    placeholder="Education Qualification"
                    required
                    value={eduQualification}
                    onChange={(event) => {
                      setEduQualification(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Designation *</label>
                  <input
                    type="text"
                    placeholder="Enter designation"
                    required
                    value={designation}
                    onChange={(event) => {
                      setDesignation(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Facebook Profile Link *</label>
                  <input
                    type="text"
                    placeholder="Facebook Profile link"
                    required
                    value={facebook}
                    onChange={(event) => {
                      setFacebook(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Instagram Profile Link *</label>
                  <input
                    type="text"
                    placeholder="Instagram Profile link"
                    required
                    value={instagram}
                    onChange={(event) => {
                      setInstagram(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Twitter Profile Link *</label>
                  <input
                    type="text"
                    placeholder="Twitter Profile link"
                    required
                    value={twitter}
                    onChange={(event) => {
                      setTwitter(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Linkedin Profile Link *</label>
                  <input
                    type="text"
                    placeholder="Linkedin Profile link"
                    required
                    value={linkedin}
                    onChange={(event) => {
                      setLinkedin(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Profile Picture *</label>
                  <div className="editProfilePicture">
                    <input
                      type="file"
                      name="picture"
                      onChange={(event) => setProfile(event.target.files[0])}
                      size="lg"
                    />
                    <img
                      src={`${API_URL}images/staff/${profile}`}
                      alt={fullName}
                    />
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateTeamData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/staff`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditTeam;
