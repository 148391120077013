import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./courseDuration.css";

// const API_URL = "http://localhost:3001/";

const InsertCourseDuration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [totalMonths, setTotalMonths] = useState("");
  const navigate = useNavigate();

  const courseDurationData = () => {
    try {
      setIsLoading(true);
      const data = {
        title,
        totalMonths,
      };

      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}course-duration`, data, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(`This course dureation is already exit!`);
        }
        //console.log(response.data);
        setTitle("");
        setTotalMonths("");
        alert("Course Duration Added Successfully!");
        setIsLoading(false);
        navigate("/admin/course-durations");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCourseHeading">Add New Course Duration</h1>
          <div className="course-form-data">
            <div className="insert-course-form grid">
              <div className="input-group">
                <label htmlFor="">Course Duration *</label>
                <input
                  type="text"
                  placeholder="Course Duration (6 Months)"
                  required
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Total Months (Number) *</label>
                <input
                  type="text"
                  placeholder="Total Months (6, 12, 24)"
                  required
                  value={totalMonths}
                  onChange={(event) => {
                    setTotalMonths(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={courseDurationData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/course-durations`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertCourseDuration;
