import axios from "axios";
import React, { useEffect, useState } from "react";
import Title from "../common/title/Title";
import { API_URL, BASE_URL } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const YoutubeCourse = ({ category }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [youtubes, setYoutubes] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}youtubes`)
        .then((response) => {
          //console.log(response.data);
          setYoutubes(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const filtered = youtubes.filter((obj) => {
    return obj.mainCategory === category;
  });

  return (
    <>
      {isLoading ? (
        <div className="loadingSpinner">
          <img
            src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
            alt="Loading.."
          />
        </div>
      ) : filtered.length > 0 ? (
        <section className="youtube">
          <div className="youtubeContainer">
            <Title
              subtitle={`Our YouTube Class of ${category}`}
              title="Browse Our Youtube Courses"
            />
            <div
              className={
                filtered.length > 1 ? "content grid" : "content courseOnlyOne"
              }
            >
              {youtubes.map((youtube, index) =>
                youtube.mainCategory === category ? (
                  <a
                    href={youtube.playlistLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <div className="box">
                      <div className="img">
                        <img
                          src={`${API_URL}images/youtubes/${youtube.thumbnailPicture}`}
                          alt={youtube.playlistTitle}
                        />
                      </div>
                      <h2>{youtube.playlistTitle}</h2>
                      <p className="course-number">
                        {youtube.totalVideoNumber}+
                      </p>
                    </div>
                  </a>
                ) : null
              )}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default YoutubeCourse;
