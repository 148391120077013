import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const DurationBoard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [durations, setDurations] = useState([]);
  const [filterDurations, setFilterDurations] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}course-duration`, config)
        .then((response) => {
          //console.log(response.data);
          setDurations(response.data);
          setFilterDurations(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setDurations, setFilterDurations]);

  useEffect(() => {
    try {
      const filteredItems = durations.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.AdminId.toString().includes(search)
      );

      setFilterDurations(filteredItems);
    } catch (error) {
      alert(error.message);
    }
  }, [search, durations]);

  const deleteHandler = (deleteId) => {
    try {
      setDeletedLoading(true);
      const id = deleteId;
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .delete(`${API_URL}course-duration/delete/${id}`, config)
        .then((response) => {
          //console.log(response.data);
          setDurations(durations.filter((duration) => duration.id !== id));
          alert("Data Deleted Successfully!");
          setDeletedLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setDeletedLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setDeletedLoading(false);
    }
  };

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => index + 1 + pageSize * (currentPage - 1),
      width: "70px",
    },
    {
      name: "Course Duration",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      id: "totalMonth",
      name: "Total Months",
      selector: (row) => row.totalMonths,
      sortable: true,
    },
    {
      name: "Added By",
      selector: (row) => row.Admin.username,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="updateButton">
          <NavLink to={`${BASE_URL}admin/edit-duration/${row.id}`}>
            <img
              src={`${BASE_URL}images/basic-icons/edit-icon.png`}
              alt="Edit Icon"
            />
          </NavLink>
          <button
            onClick={() => deleteHandler(row.id)}
            disabled={deletedLoading}
            className={deletedLoading ? "disableBtn" : null}
          >
            {deletedLoading ? (
              <div className="countSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <img
                src={`${BASE_URL}images/basic-icons/delete-icon.png`}
                alt="Delete Icon"
              />
            )}
          </button>
        </div>
      ),
      width: "120px",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="itemContainer">
            <div className="addButton">
              <h1>Course Durations</h1>
              <div className="tableBtn">
                <NavLink to={`${BASE_URL}admin/add-duration`}>
                  Add Duration
                </NavLink>
                &nbsp;&nbsp;
                <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
              </div>
            </div>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={filterDurations.length >= 0 ? filterDurations : durations}
                pagination
                responsive
                striped
                fixedHeader
                fixedHeaderScrollHeight="450px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    className="searchInput"
                    placeholder="Search Here"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                }
                defaultSortFieldId="totalMonth"
                defaultSortAsc={true}
                paginationPerPage={pageSize}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationTotalRows={filterDurations.length || durations.length}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                  setPageSize(currentRowsPerPage);
                  setCurrentPage(currentPage);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DurationBoard;
