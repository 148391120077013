import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Back from "../common/back/Back";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import Faq from "../faq/Faq";
import "./contact.css";

// const API_URL = "http://localhost:3001/";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState("");

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us | {`${settings.metaTitle}`}</title>
        <link rel="canonical" href={`${BASE_URL}about`} />
        <meta name="description" content={`${settings.metaDescription}`} />
        <meta name="keywords" content={`${settings.metaKeyword}`} />
      </Helmet>
      <Back title="Contact Us" />
      <div className="contactBg">
        <div className="container">
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="subContainer">
              <Faq />
              <section className="contact">
                <div className="shadow grid4">
                  <div className="left">
                    <iframe src={settings.googleMap} title="google map"></iframe>
                  </div>
                  <div className="right">
                    <h2>Contact Us</h2>
                    <p>{settings.description}</p>

                    <div className="items grid2">
                      <div className="box">
                        <h3>ADDRESS:</h3>
                        <p>{settings.address}</p>
                      </div>
                      <div className="box">
                        <h3>E-mail:</h3>
                        <p>{settings.email}</p>
                      </div>
                      <div className="box">
                        <h3>Contact Number:</h3>
                        <p>
                          {settings.phone}
                          <br />
                          <strong>
                            <em>01521-117690 (Developer)</em>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Contact;
