import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./testimonial.css";

// const API_URL = "http://localhost:3001/";

const animatedComponents = makeAnimated();

const InsertTestimonial = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [name, setName] = useState("");
  const [selectCourse, setSelectCourse] = useState("");
  const [description, setDescription] = useState("");
  const [picture, setPicture] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses`, config)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const insertTestimonialData = () => {
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("name", name);
      formData.append("courseTitle", selectCourse);
      formData.append("description", description);
      formData.append("picture", picture);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .post(`${API_URL}testimonials`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setIsLoading(false);
            return alert(`This testimonial is already exit!`);
          }
          //console.log(response.data);
          alert("Testimonial Added Successfully!");
          setIsLoading(false);
          navigate("/admin/testimonials");
        });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findSubjectHeading">Add New Testimonial</h1>
          <div className="insert-form-data">
            <div className="insert-testimonial-form">
              <div className="insert-input-group">
                <div className="input-group">
                  <label htmlFor="">Name*</label>
                  <input
                    type="text"
                    placeholder="Enter Full Name"
                    required
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Title *</label>
                  <Select
                    components={animatedComponents}
                    options={[...courses]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (course, index) {
                        return {
                          key: index,
                          value: course.title,
                          label: course.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectCourse(event.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Student Picture *</label>
                  <input
                    type="file"
                    name="picture"
                    onChange={(event) => setPicture(event.target.files[0])}
                    size="lg"
                  />
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="">Description *</label>
                <textarea
                  placeholder="Write Testimonial Details..."
                  required
                  rows="10"
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={insertTestimonialData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/testimonials`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertTestimonial;
