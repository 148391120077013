import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./staff.css";

// const API_URL = "http://localhost:3001/";

const InsertTeamForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [eduQualification, setEduQualification] = useState("");
  const [designation, setDesignation] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [imageProfile, setImageProfile] = useState("");

  const navigate = useNavigate();

  const insertResultData = () => {
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("fullName", name);
      formData.append("eduQualification", eduQualification);
      formData.append("designation", designation);
      formData.append("facebookLink", facebookLink);
      formData.append("instagramLink", instagramLink);
      formData.append("twitterLink", twitterLink);
      formData.append("linkedinLink", linkedinLink);
      formData.append("profilePicture", imageProfile);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}teams`, formData, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(response.data.error);
        }
        //console.log(response.data);
        alert("Staff Added Successfully!");
        setName("");
        setEduQualification("");
        setDesignation("");
        setFacebookLink("");
        setInstagramLink("");
        setTwitterLink("");
        setLinkedinLink("");
        setImageProfile("");
        setIsLoading(false);
        navigate("/admin/staff");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findTeamHeading">Add New Staff/Doctor Details</h1>
          <div className="team-form-data">
            <div className="insert-team-form">
              <div className="input-group">
                <label htmlFor="">Full Name *</label>
                <input
                  type="text"
                  placeholder="Full name"
                  required
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Education Qualification *</label>
                <input
                  type="text"
                  placeholder="Education Qualification"
                  required
                  value={eduQualification}
                  onChange={(event) => {
                    setEduQualification(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Designation *</label>
                <input
                  type="text"
                  placeholder="Enter designation"
                  required
                  value={designation}
                  onChange={(event) => {
                    setDesignation(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Facebook Profile Link *</label>
                <input
                  type="text"
                  placeholder="Facebook Profile link"
                  required
                  value={facebookLink}
                  onChange={(event) => {
                    setFacebookLink(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Instagram Profile Link *</label>
                <input
                  type="text"
                  placeholder="Instagram Profile link"
                  required
                  value={instagramLink}
                  onChange={(event) => {
                    setInstagramLink(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Twitter Profile Link *</label>
                <input
                  type="text"
                  placeholder="Twitter Profile link"
                  required
                  value={twitterLink}
                  onChange={(event) => {
                    setTwitterLink(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Linkedin Profile Link *</label>
                <input
                  type="text"
                  placeholder="Linkedin Profile link"
                  required
                  value={linkedinLink}
                  onChange={(event) => {
                    setLinkedinLink(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Profile Picture *</label>
                <input
                  type="file"
                  name="picture"
                  onChange={(event) => setImageProfile(event.target.files[0])}
                  size="lg"
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={insertResultData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/staff`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertTeamForm;
