import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import Counters from "../counter/Counters";
import Features from "../features/Features";
import Hero from "../hero/Hero";
import Testimonial from "../testimonials/Testimonial";
import HBlog from "./HBlog";
import HomeCourseDetails from "./HomeCourseDetails";

// const API_URL = "http://localhost:3001/";

const Home = () => {
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | {`${settings.metaTitle}`}</title>
        <link rel="canonical" href={`${BASE_URL}`} />
        <meta name="description" content={`${settings.metaDescription}`} />
        <meta name="keywords" content={`${settings.metaKeyword}`} />
      </Helmet>
      <div className="hrtd">
        <Hero />
        <HomeCourseDetails />
        <Features />
        <Counters />
        <Testimonial />
        <HBlog />
      </div>
    </>
  );
};

export default Home;
