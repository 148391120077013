import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const FaqBoard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [filterFaqs, setFilterFaqs] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}faqs`, config)
        .then((response) => {
          //console.log(response.data);
          setFaqs(response.data);
          setFilterFaqs(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setFaqs, setFilterFaqs]);

  useEffect(() => {
    try {
      const filteredItems = faqs.filter(
        (item) =>
          item.question.toLowerCase().includes(search.toLowerCase()) ||
          item.answer.toLowerCase().includes(search.toLowerCase()) ||
          item.AdminId.toString().includes(search)
      );

      setFilterFaqs(filteredItems);
    } catch (error) {
      alert(error.message);
    }
  }, [search, faqs]);

  const deleteHandler = (deleteId) => {
    try {
      setDeletedLoading(true);
      const id = deleteId;
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .delete(`${API_URL}faqs/delete/${id}`, config)
        .then((response) => {
          //console.log(response.data);
          setFaqs(faqs.filter((faq) => faq.id !== id));
          alert("Data Deleted Successfully!");
          setDeletedLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setDeletedLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setDeletedLoading(false);
    }
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => index + 1 + pageSize * (currentPage - 1),
      width: "70px",
    },
    {
      id: "question",
      name: "Questions",
      selector: (row) => row.question,
      sortable: true,
      cell: (row, index) => (
        <div
          className="hover-cell"
          title={row.question}
          onMouseEnter={() => handleRowHover(index)}
          onMouseLeave={() => handleRowHover(null)}
        >
          {hoveredRowIndex === index
            ? row.question
            : truncateText(row.question, 200)}
        </div>
      ),
    },
    {
      name: "Answers",
      selector: (row) => row.answer,
      sortable: true,
      cell: (row, index) => (
        <div
          className="hover-cell"
          title={row.answer}
          onMouseEnter={() => handleRowHover(index)}
          onMouseLeave={() => handleRowHover(null)}
        >
          {hoveredRowIndex === index
            ? row.answer
            : truncateText(row.answer, 200)}
        </div>
      ),
    },
    {
      name: "Added By",
      selector: (row) => row.Admin.username,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="updateButton">
          <NavLink to={`${BASE_URL}admin/edit-faq/${row.id}`}>
            <img
              src={`${BASE_URL}images/basic-icons/edit-icon.png`}
              alt="Edit Icon"
            />
          </NavLink>
          <button
            onClick={() => deleteHandler(row.id)}
            disabled={deletedLoading}
            className={deletedLoading ? "disableBtn" : null}
          >
            {deletedLoading ? (
              <div className="countSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <img
                src={`${BASE_URL}images/basic-icons/delete-icon.png`}
                alt="Delete Icon"
              />
            )}
          </button>
        </div>
      ),
      width: "120px",
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };
  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="itemContainer">
            <div className="addButton">
              <h1>FAQs</h1>
              <div className="tableBtn">
                <NavLink to={`${BASE_URL}admin/add-faq`}>Add FAQ</NavLink>
                &nbsp;&nbsp;
                <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
              </div>
            </div>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={filterFaqs.length >= 0 ? filterFaqs : faqs}
                pagination
                responsive
                striped
                fixedHeader
                fixedHeaderScrollHeight="450px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    className="searchInput"
                    placeholder="Search Here"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                }
                defaultSortFieldId="question"
                defaultSortAsc={true}
                paginationPerPage={pageSize}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationTotalRows={filterFaqs.length || faqs.length}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                  setPageSize(currentRowsPerPage);
                  setCurrentPage(currentPage);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqBoard;
