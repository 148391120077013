import React from "react";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../constants/Constants";

const CardNumber = ({ cardTitle, cardValue, cardLink }) => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title-number">
            <h3>{cardTitle}</h3>
            <p>
              {cardValue <= 0 ? (
                <div className="countSpinner">
                  <img
                    src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                    alt="Loading.."
                  />
                </div>
              ) : (
                cardValue
              )}
            </p>
          </div>
          <div className="card-picture">
            <img
              src={`${BASE_URL}images/basic-icons/statistics.png`}
              alt={cardTitle}
            />
          </div>
        </div>
        <div className="card-link">
          <NavLink to={cardLink}>View More</NavLink>
        </div>
      </div>
    </>
  );
};

export default CardNumber;
