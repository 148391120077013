import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./result.css";

// const API_URL = "http://localhost:3001/";

const options = [
  { value: "A+", label: "A+" },
  { value: "A", label: "A" },
  { value: "A-", label: "A-" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "F", label: "F" },
];

const years = [];
const currentYear = new Date().getFullYear();
for (let i = 2014; i <= currentYear; i++) {
  years.push({ value: i, label: i });
}

const animatedComponents = makeAnimated();

const EditResult = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { resultId } = useParams();
  const [courses, setCourses] = useState([]);
  const [courseDuration, setCourseDuration] = useState([]);
  const [batches, setBatches] = useState([]);
  const [courseSession, setCourseSession] = useState([]);
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [selectDuration, setSelectDuration] = useState("");
  const [selectBatch, setSelectBatch] = useState("");
  const [studentId, setStudentId] = useState("");
  const [regId, setRegId] = useState("");
  const [selectSession, setSelectSession] = useState("");
  const [fullMark, setFullMark] = useState("");
  const [resultGPA, setResultGPA] = useState("");
  const [resultGrade, setResultGrade] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [imageProfile, setImageProfile] = useState("");
  const [updatePicture, setEditImageProfile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses`, config)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}course-duration`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseDuration(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}batches`, config)
        .then((response) => {
          //console.log(response.data);
          setBatches(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}course-session`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseSession(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}result/edit/${resultId}`, config)
        .then((response) => {
          //console.log(response.data);
          setName(response.data.name);
          setFatherName(response.data.fatherName);
          setMotherName(response.data.motherName);
          setCourseTitle(response.data.courseTitle);
          setSelectDuration(response.data.courseDuration);
          setSelectBatch(response.data.batch);
          setStudentId(response.data.studentId);
          setRegId(response.data.regId);
          setSelectSession(response.data.session);
          setFullMark(response.data.fullMark);
          setResultGPA(response.data.resultGPA);
          setResultGrade(response.data.resultGrade);
          setPassingYear(response.data.passingYear);
          setImageProfile(response.data.picture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [resultId]);

  const updateResultData = () => {
    try {
      setUpdatedLoading(true);
      var formData = new FormData();
      formData.append("name", name);
      formData.append("fatherName", fatherName);
      formData.append("motherName", motherName);
      formData.append("courseTitle", courseTitle);
      formData.append("courseDuration", selectDuration);
      formData.append("batch", selectBatch);
      formData.append("studentId", studentId);
      formData.append("regId", regId);
      formData.append("session", selectSession);
      formData.append("fullMark", fullMark);
      formData.append("resultGPA", resultGPA);
      formData.append("resultGrade", resultGrade);
      formData.append("passingYear", passingYear);
      
      if (updatePicture) {
        formData.append("updatePicture", updatePicture);
      } else {
        formData.append("picture", imageProfile);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}result/edit/${resultId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          setName("");
          setFatherName("");
          setMotherName("");
          setCourseTitle("");
          setSelectDuration("");
          setSelectBatch("");
          setStudentId("");
          setRegId("");
          setSelectSession("");
          setFullMark("");
          setResultGPA("");
          setResultGrade("");
          setPassingYear("");
          setImageProfile("");
          alert("Result Data Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/results");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findResultHeading">Edit Result Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="insert-form-data">
              <div className="insert-result-form">
                <div className="input-group">
                  <label htmlFor="">Student Name *</label>
                  <input
                    type="text"
                    placeholder="Enter full name"
                    required
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Father Name *</label>
                  <input
                    type="text"
                    placeholder="Enter father full name"
                    required
                    value={fatherName}
                    onChange={(event) => {
                      setFatherName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Mother Name *</label>
                  <input
                    type="text"
                    placeholder="Enter mother full name"
                    required
                    value={motherName}
                    onChange={(event) => {
                      setMotherName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Title *</label>
                  <Select
                    components={animatedComponents}
                    options={[...courses]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (course, index) {
                        return {
                          key: index,
                          value: course.title,
                          label: course.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setCourseTitle(event.value)}
                  />
                  <p>Selected Options: {courseTitle}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Duration *</label>
                  <Select
                    name="CourseDuration"
                    components={animatedComponents}
                    options={[...courseDuration]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (duration, index) {
                        return {
                          key: index,
                          value: duration.title,
                          label: duration.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectDuration(event.value)}
                  />
                  <p>Selected Options: {selectDuration}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Batch *</label>
                  <Select
                    components={animatedComponents}
                    options={[...batches]
                      .sort((a, b) => (a.title > b.title ? -1 : 1))
                      .map(function (batch, index) {
                        return {
                          key: index,
                          value: batch.title,
                          label: batch.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectBatch(event.value)}
                  />
                  <p>Selected Options: {selectBatch}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Student ID *</label>
                  <input
                    type="text"
                    placeholder="Enter student id"
                    required
                    value={studentId}
                    onChange={(event) => {
                      setStudentId(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Registration No *</label>
                  <input
                    type="text"
                    placeholder="Enter registration number"
                    required
                    value={regId}
                    onChange={(event) => {
                      setRegId(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Session *</label>
                  <Select
                    components={animatedComponents}
                    options={[...courseSession]
                      .sort((a, b) => (a.title > b.title ? -1 : 1))
                      .map(function (session, index) {
                        return {
                          key: index,
                          value: session.title,
                          label: session.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectSession(event.value)}
                  />
                  <p>Selected Options: {selectSession}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Full Marks *</label>
                  <input
                    type="text"
                    placeholder="Enter full marks"
                    required
                    value={fullMark}
                    onChange={(event) => {
                      setFullMark(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Result CGPA *</label>
                  <input
                    type="text"
                    placeholder="Enter full marks"
                    required
                    value={resultGPA}
                    onChange={(event) => {
                      setResultGPA(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Result Grade *</label>
                  <Select
                    components={animatedComponents}
                    options={options}
                    closeMenuOnSelect={true}
                    onChange={(event) => setResultGrade(event.value)}
                  />
                  <p>Selected Options: {resultGrade}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Passing Year *</label>
                  <Select
                    components={animatedComponents}
                    options={[...years].sort((a, b) => b.value - a.value)}
                    closeMenuOnSelect={true}
                    onChange={(event) => setPassingYear(event.value)}
                  />
                  <p>Selected Options: {passingYear}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Student Picture *</label>
                  <div className="editProfilePicture">
                    <input
                      type="file"
                      name="updatePicture"
                      onChange={(event) =>
                        setEditImageProfile(event.target.files[0])
                      }
                      size="lg"
                    />
                    <img
                      src={`${API_URL}images/results/${imageProfile}`}
                      alt={name}
                    />
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateResultData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/results`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditResult;
