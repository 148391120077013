import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./testimonial.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const TestimonialView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { testimonialId } = useParams();
  const [name, setName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [description, setDescription] = useState("");
  const [picture, setPicture] = useState("");
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}testimonials/edit/${testimonialId}`, config)
        .then((response) => {
          //console.log(response.data);
          setName(response.data.name);
          setCourseTitle(response.data.courseTitle);
          setDescription(response.data.description);
          setPicture(response.data.picture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [testimonialId]);

  return (
    <>
      <div className="container">
        <div className="subContainer">
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <section className="testimonial">
              <div className="content view">
                <div className="items shadow">
                  <div className="box">
                    <div className="img">
                      <img
                        src={`${API_URL}images/testimonials/${picture}`}
                        alt={name}
                      />
                      <i className="fa fa-quote-left icon"></i>
                    </div>
                    <div className="name">
                      <h2>{name}</h2>
                      <span>{courseTitle}</span>
                      <h3>{settings.title}</h3>
                    </div>
                  </div>
                  <p>{description}</p>
                </div>
              </div>
              <div className="submit-btn">
                <NavLink to={`${BASE_URL}admin/testimonials`}>Back</NavLink>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default TestimonialView;
