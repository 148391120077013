import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./apply.css";

// const API_URL = "http://localhost:3001/";

const options = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
  { value: "None", label: "None" },
];

const paymentOptions = [
  { value: "BKash", label: "BKash" },
  { value: "Rocket", label: "Rocket" },
  { value: "Bank", label: "Bank" },
  { value: "Cash", label: "Cash" },
];

const InsertStudent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [courseDuration, setCourseDuration] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [selectCourseTitle, setSelectCourseTitle] = useState("");
  const [selectCourseDuration, setSelectCourseDuration] = useState("");
  const [nameBangla, setNameBangla] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [fatherNameBangla, setFatherNameBangla] = useState("");
  const [fatherNameEnglish, setFatherNameEnglish] = useState("");
  const [motherNameBangla, setMotherNameBangla] = useState("");
  const [motherNameEnglish, setMotherNameEnglish] = useState("");
  const [presentAddress, setPresentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [selectRequirement, setSelectRequirement] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState("");
  const [blood, setBlood] = useState("");
  const [days, setDays] = useState("");
  const [shift, setShift] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [phone, setPhone] = useState("");
  const [studentPicture, setStudentPicture] = useState("");
  //   const [nidPicture, setNidPicture] = useState("");
  //   const [certificatePicture, setCertificatePicture] = useState("");

  //This is Select Options Animations
  const animatedComponents = makeAnimated();

  //For Navigate
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses`, config)
        .then((response) => {
          //console.log(response.data);
          setCourses(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}course-duration`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseDuration(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}requires`, config)
        .then((response) => {
          //console.log(response.data);
          setRequirements(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("courseTitle", selectCourseTitle);
      formData.append("courseDuration", selectCourseDuration);
      formData.append("nameBangla", nameBangla);
      formData.append("nameEnglish", nameEnglish);
      formData.append("fnameBangla", fatherNameBangla);
      formData.append("fnameEnglish", fatherNameEnglish);
      formData.append("mnameBangla", motherNameBangla);
      formData.append("mnameEnglish", motherNameEnglish);
      formData.append("presentAddress", presentAddress);
      formData.append("permanentAddress", permanentAddress);
      formData.append("eduQualification", selectRequirement);
      formData.append("dateOfBirth", dob);
      formData.append("nationality", nationality);
      formData.append("blood", blood);
      formData.append("days", days);
      formData.append("shift", shift);
      formData.append("paymentMethod", paymentMethod);
      formData.append("transactionId", transactionId);
      formData.append("phone", phone);
      formData.append("profilePicture", studentPicture);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .post(`${API_URL}applies/student`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setIsLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          alert("Application Submitted Successfully!");
          setSelectCourseTitle("");
          setSelectCourseDuration("");
          setNameBangla("");
          setNameEnglish("");
          setFatherNameBangla("");
          setFatherNameEnglish("");
          setMotherNameBangla("");
          setMotherNameEnglish("");
          setPresentAddress("");
          setPermanentAddress("");
          setSelectRequirement("");
          setDob("");
          setNationality("");
          setBlood("");
          setDays("");
          setShift("");
          setPaymentMethod("");
          setTransactionId("");
          setPhone("");
          setStudentPicture("");
          event.target.reset();
          setIsLoading(false);
          navigate(`/apply/download/${transactionId}`);
        });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="InsertApplyHeading">
            <h1>Add New Students Details</h1>
          </div>
          <form onSubmit={onSubmit}>
            <div className="form-data">
              <div className="apply-form">
                <div className="input-group">
                  <label htmlFor="">Course Title *</label>
                  <Select
                    components={animatedComponents}
                    options={[...courses]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (course, index) {
                        return {
                          key: index,
                          value: course.title,
                          label: course.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectCourseTitle(event.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Duration *</label>
                  <Select
                    name="CourseDuration"
                    components={animatedComponents}
                    options={[...courseDuration]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (duration, index) {
                        return {
                          key: index,
                          value: duration.title,
                          label: duration.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectCourseDuration(event.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Full Name (Bangla)*</label>
                  <input
                    type="text"
                    placeholder="Name (Bangla)"
                    required
                    value={nameBangla}
                    onChange={(event) => {
                      setNameBangla(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Full Name (English)*</label>
                  <input
                    type="text"
                    placeholder="Name (English)"
                    required
                    value={nameEnglish}
                    onChange={(event) => {
                      setNameEnglish(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Father Name (Bangla)*</label>
                  <input
                    type="text"
                    placeholder="Father name (Bangla)"
                    required
                    value={fatherNameBangla}
                    onChange={(event) => {
                      setFatherNameBangla(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Father Name (English)*</label>
                  <input
                    type="text"
                    placeholder="Father name (English)"
                    required
                    value={fatherNameEnglish}
                    onChange={(event) => {
                      setFatherNameEnglish(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Mother Name (Bangla)*</label>
                  <input
                    type="text"
                    placeholder="Mother name (Bangla)"
                    required
                    value={motherNameBangla}
                    onChange={(event) => {
                      setMotherNameBangla(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Mother Name (English)*</label>
                  <input
                    type="text"
                    placeholder="Mother name (English)"
                    required
                    value={motherNameEnglish}
                    onChange={(event) => {
                      setMotherNameEnglish(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Present Address *</label>
                  <textarea
                    value={presentAddress}
                    placeholder="Write Present Address"
                    rows={4}
                    onChange={(event) => {
                      setPresentAddress(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Permanent Address *</label>
                  <textarea
                    value={permanentAddress}
                    placeholder="Write Permanent Address"
                    rows={4}
                    onChange={(event) => {
                      setPermanentAddress(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Education Qualification *</label>
                  <Select
                    components={animatedComponents}
                    options={[...requirements]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (course, index) {
                        return {
                          key: index,
                          value: course.title,
                          label: course.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectRequirement(event.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Date of birth *</label>
                  <input
                    type="date"
                    required
                    value={dob}
                    onChange={(event) => {
                      setDob(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Nationality*</label>
                  <input
                    type="text"
                    placeholder="Nationality (Bangladeshi)"
                    required
                    value={nationality}
                    onChange={(event) => {
                      setNationality(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Blood Group *</label>
                  <Select
                    components={animatedComponents}
                    options={options}
                    closeMenuOnSelect={true}
                    onChange={(event) => setBlood(event.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Select Day *</label>
                  <select
                    value={days}
                    onChange={(event) => setDays(event.target.value)}
                  >
                    <option>-Select-</option>
                    <option>Friday</option>
                    <option>Monday</option>
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="">Select Shift *</label>
                  <select
                    value={shift}
                    onChange={(event) => setShift(event.target.value)}
                  >
                    <option>-Select-</option>
                    <option>Morning</option>
                    <option>Evening</option>
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="">Select Payment Method *</label>
                  <Select
                    components={animatedComponents}
                    options={paymentOptions}
                    closeMenuOnSelect={true}
                    onChange={(event) => setPaymentMethod(event.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Transaction ID or MR Number*</label>
                  <input
                    type="text"
                    placeholder="Transaction ID (Bkash, Rocket, Bank, Cash)"
                    required
                    value={transactionId}
                    onChange={(event) => {
                      setTransactionId(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Contact Number*</label>
                  <input
                    type="text"
                    placeholder="Contact Number"
                    required
                    value={phone}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Upload Picture *</label>
                  <input
                    type="file"
                    name="studentPicture"
                    onChange={(event) =>
                      setStudentPicture(event.target.files[0])
                    }
                    size="lg"
                  />
                </div>
              </div>
              <div className="submit-btn">
                <input
                  type="submit"
                  value={isLoading ? "Saving.." : "Save"}
                  disabled={isLoading}
                  className={isLoading ? "disableBtn" : null}
                />
                <NavLink to={`${BASE_URL}admin/students`}>Back</NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default InsertStudent;
