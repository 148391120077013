import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./admin.css";
// const API_URL = "http://localhost:3001/";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userEmail } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [textError, setTextError] = useState("");
  const navigate = useNavigate();

  //   useEffect(() => {
  //     axios
  //       .get(
  //         `${API_URL}admins/forgot-password/${userEmail}`
  //       )
  //       .then((response) => {
  //         //console.log(response.data);
  //         setUserInfo(response.data.title);
  //       })
  //       .catch((error) => {
  //         alert(error.message);
  //       });
  //   }, [userEmail]);

  const forgotData = () => {
    try {
      setIsLoading(true);
      if (password === confirmPassword && password !== "") {
        setTextError("");
        const data = {
          password,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios
          .put(`${API_URL}admins/forgot-password/${userEmail}`, data, config)
          .then((response) => {
            if (response.data.error) {
              setIsLoading(false);
              return alert(response.data.error);
            }
            alert("Password Updated Successfully!");
            setIsLoading(false);
            navigate("/admin/login");
            //console.log(response.data);
          });
      } else {
        setIsLoading(false);
        setTextError(`Password don't match!`);
      }
    } catch (error) {
      setIsLoading(false);
      alert(error.message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="login-form-data">
            <h1 className="findAdminHeading">Change Password</h1>
            <div className="login-insert-form">
              <div className="input-group">
                <label htmlFor="">New Password *</label>
                <input
                  type="password"
                  placeholder="New Password"
                  required
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Confirm Password *</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="submit-btn forgot-btn">
              <button
                onClick={forgotData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Changing.." : "Change"}
              </button>
            </div>
            <div className="textError">{textError}</div>
            <div className="forgot-password">
              <span>
                <NavLink to={`${BASE_URL}admin/login`}>
                  Don't Change Password?
                </NavLink>
              </span>
              <span>
                <NavLink to={`${BASE_URL}admin/login`}>
                  Don't have an account? Contact Admin.
                </NavLink>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
