import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./admin.css";
//const API_URL = "http://localhost:3001/";

const Setting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [faviconLoading, setFaviconLoading] = useState(false);
  const [basicLoading, setBasicLoading] = useState(false);
  const [seoLoading, setSeoLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [id, setId] = useState(null);
  const [siteTitle, setSiteTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [copyright, setCopyright] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [googleMap, setGoogleMap] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");

  useEffect(() => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`, config)
        .then((response) => {
          //console.log(response.data);
          setId(response.data.id);
          setSiteTitle(response.data.title);
          setSubTitle(response.data.subTitle);
          setDescription(response.data.description);
          setLogo(response.data.logo);
          setFavicon(response.data.favicon);
          setCopyright(response.data.copyright);
          setAddress(response.data.address);
          setPhone(response.data.phone);
          setEmail(response.data.email);
          setGoogleMap(response.data.googleMap);
          setMetaTitle(response.data.metaTitle);
          setMetaKeyword(response.data.metaKeyword);
          setMetaDescription(response.data.metaDescription);
          setFacebook(response.data.facebook);
          setInstagram(response.data.instagram);
          setTwitter(response.data.twitter);
          setLinkedin(response.data.linkedin);
          setYoutube(response.data.youtube);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const updateLogo = () => {
    try {
      setLogoLoading(true);
      var formData = new FormData();
      if (logo) {
        formData.append("logo", logo);
      } else {
        formData.append("logo", logo);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}setting/update-logo/${id}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setLogoLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          alert("Website Loog Updated Successfully!");
          setLogoLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setLogoLoading(false);
    }
  };

  const updateFavicon = () => {
    try {
      setFaviconLoading(true);
      var formData = new FormData();
      if (favicon) {
        formData.append("favicon", favicon);
      } else {
        formData.append("favicon", favicon);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}setting/update-favicon/${id}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setFaviconLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          alert("Website Favicon Updated Successfully!");
          setFaviconLoading(false);
        });
    } catch (error) {
      setFaviconLoading(false);
      alert(error.message);
    }
  };

  const updateBasicSetting = () => {
    try {
      setBasicLoading(true);
      const data = {
        title: siteTitle,
        subTitle,
        description,
        copyright,
        address,
        phone,
        email,
        googleMap,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}setting/update-basic/${id}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setBasicLoading(false);
            return alert(`Basic setting is already exit!`);
          }
          //console.log(response.data);
          alert("Basic Setting Updated Successfully!");
          setBasicLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setBasicLoading(false);
    }
  };

  const updateSEO = () => {
    try {
      setSeoLoading(true);
      const data = {
        metaTitle,
        metaKeyword,
        metaDescription,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}setting/update-seo/${id}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setSeoLoading(false);
            return alert(`SEO setting is already exit!`);
          }
          //console.log(response.data);
          alert("SEO Updated Successfully!");
          setSeoLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setSeoLoading(false);
    }
  };

  const updateSocial = () => {
    try {
      setSocialLoading(true);
      const data = {
        facebook,
        instagram,
        twitter,
        linkedin,
        youtube,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}setting/update-social/${id}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setSocialLoading(false);
            return alert(`Basic setting is already exit!`);
          }
          //console.log(response.data);
          alert("Social Updated Successfully!");
          setSocialLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setSocialLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="InsertApplyHeading">
            <h1>Update Setting Details</h1>
          </div>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="admin-setting">
              <div className="logo-favicon">
                <div className="admin-form-data">
                  <div className="admin-form">
                    <div className="input-group">
                      <label htmlFor="">Upload Logo *</label>
                      <div className="editProfilePicture">
                        <input
                          type="file"
                          name="logo"
                          onChange={(event) => setLogo(event.target.files[0])}
                          size="lg"
                        />
                        <img
                          src={`${API_URL}images/website/${logo}`}
                          alt={siteTitle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="submit-btn">
                    <button
                      onClick={updateLogo}
                      disabled={logoLoading}
                      className={logoLoading ? "disableBtn" : null}
                    >
                      {logoLoading ? "Changing.." : "Change"}
                    </button>
                    <NavLink to={`${BASE_URL}admin/dashboard`}>
                      Dashboard
                    </NavLink>
                  </div>
                </div>
                <div className="admin-form-data">
                  <div className="admin-form">
                    <div className="input-group">
                      <label htmlFor="">Upload Favicon *</label>
                      <div className="editProfilePicture">
                        <input
                          type="file"
                          name="favicon"
                          onChange={(event) =>
                            setFavicon(event.target.files[0])
                          }
                          size="lg"
                        />
                        <img
                          src={`${API_URL}images/website/${favicon}`}
                          alt={siteTitle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="submit-btn">
                    <button
                      onClick={updateFavicon}
                      disabled={faviconLoading}
                      className={faviconLoading ? "disableBtn" : null}
                    >
                      {faviconLoading ? "Changing.." : "Change"}
                    </button>
                    <NavLink to={`${BASE_URL}admin/dashboard`}>
                      Dashboard
                    </NavLink>
                  </div>
                </div>
              </div>
              <hr className="dashboard-hr" />
              <div className="admin-form-data">
                <div className="admin-insert-form">
                  <div className="input-group">
                    <label htmlFor="">Site Title (Max-20 Characters)*</label>
                    <input
                      type="text"
                      placeholder="Site Title"
                      required
                      value={siteTitle}
                      onChange={(event) => {
                        setSiteTitle(event.target.value);
                      }}
                      disabled={true}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Sub Title (Max-70 Characters)*</label>
                    <input
                      type="text"
                      placeholder="Sub Title"
                      required
                      value={subTitle}
                      onChange={(event) => {
                        setSubTitle(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Description (Max-350 Characters)*</label>
                    <textarea
                      value={description}
                      placeholder="Description"
                      rows={6}
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Address *</label>
                    <textarea
                      value={address}
                      placeholder="Address"
                      rows={6}
                      onChange={(event) => {
                        setAddress(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Copyright Text*</label>
                    <input
                      type="text"
                      placeholder="Copyright Text"
                      required
                      value={copyright}
                      onChange={(event) => {
                        setCopyright(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">
                      Phone (Use Comma (,) for Separate) *
                    </label>
                    <input
                      type="text"
                      placeholder="Phone"
                      required
                      value={phone}
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Email *</label>
                    <input
                      type="text"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">
                      Google Map Link*
                    </label>
                    <textarea
                      value={googleMap}
                      placeholder="googleMap"
                      rows={6}
                      onChange={(event) => {
                        setGoogleMap(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    onClick={updateBasicSetting}
                    disabled={basicLoading}
                    className={basicLoading ? "disableBtn" : null}
                  >
                    {basicLoading ? "Changing.." : "Change"}
                  </button>
                  <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
                </div>
              </div>
              <hr className="dashboard-hr" />
              <div className="admin-form-data">
                <div className="admin-insert-form">
                  <div className="input-group">
                    <label htmlFor="">
                      Meta Description (160-300 Characters)*
                    </label>
                    <textarea
                      value={metaDescription}
                      placeholder="Description"
                      rows={6}
                      onChange={(event) => {
                        setMetaDescription(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Meta Keyword (Min-5 & Max-10)*</label>
                    <textarea
                      value={metaKeyword}
                      placeholder="Meta Keyword"
                      rows={6}
                      onChange={(event) => {
                        setMetaKeyword(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Meta Title (60-70 Characters) *</label>
                    <input
                      type="text"
                      placeholder="Enter Meta Title"
                      required
                      value={metaTitle}
                      onChange={(event) => {
                        setMetaTitle(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    onClick={updateSEO}
                    disabled={seoLoading}
                    className={seoLoading ? "disableBtn" : null}
                  >
                    {seoLoading ? "Changing.." : "Change"}
                  </button>
                  <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
                </div>
              </div>
              <hr className="dashboard-hr" />
              <div className="admin-form-data">
                <div className="admin-insert-form">
                  <div className="input-group">
                    <label htmlFor="">Facebook *</label>
                    <input
                      type="text"
                      placeholder="Enter Facebook Link"
                      required
                      value={facebook}
                      onChange={(event) => {
                        setFacebook(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Instagram *</label>
                    <input
                      type="text"
                      placeholder="Enter Instagram Link"
                      required
                      value={instagram}
                      onChange={(event) => {
                        setInstagram(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Twitter *</label>
                    <input
                      type="text"
                      placeholder="Enter Twitter Link"
                      required
                      value={twitter}
                      onChange={(event) => {
                        setTwitter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Linkedin *</label>
                    <input
                      type="text"
                      placeholder="Enter Linkedin Link"
                      required
                      value={linkedin}
                      onChange={(event) => {
                        setLinkedin(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">YouTube *</label>
                    <input
                      type="text"
                      placeholder="Enter Youtube Link"
                      required
                      value={youtube}
                      onChange={(event) => {
                        setYoutube(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    onClick={updateSocial}
                    disabled={socialLoading}
                    className={socialLoading ? "disableBtn" : null}
                  >
                    {socialLoading ? "Changing.." : "Change"}
                  </button>
                  <NavLink to={`${BASE_URL}admin/dashboard`}>Dashboard</NavLink>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Setting;
