import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./blog.css";

// const API_URL = "http://localhost:3001/";

const animatedComponents = makeAnimated();

const InsertBlogsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mainCategories, setMainCategories] = useState([]);
  const [selectCategories, setSelectCategories] = useState("");
  const [allWebsites, setAllWebsites] = useState([]);
  const [selectWebsite, setSelectWebsite] = useState(WEBSITE);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [featureImage, setFeatureImage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}categories`, config)
        .then((response) => {
          //console.log(response.data);
          setMainCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}setting`, config)
        .then((response) => {
          //console.log(response.data);
          setAllWebsites(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  const blogContentHandler = (content) => {
    setBlogDescription(content);
  };

  const insertResultData = () => {
    const slug = metaTitle.toLowerCase().split(" ").join("-");
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("category", selectCategories);
      formData.append("website", selectWebsite);
      formData.append("title", blogTitle);
      formData.append("metaTitle", metaTitle);
      formData.append("slug", slug);
      formData.append("keywords", metaKeyword);
      formData.append("metaDesc", metaDescription);
      formData.append("desc", blogDescription);
      formData.append("featureImage", featureImage);

      //console.log(formData);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}blogs`, formData, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(response.data.error);
        }
        //console.log(response.data);
        setSelectCategories("");
        setSelectWebsite("");
        setBlogTitle("");
        setMetaTitle("");
        setMetaKeyword("");
        setMetaDescription("");
        setBlogDescription("");
        setFeatureImage("");
        alert("Blog Data Created Successfully!");
        setIsLoading(false);
        navigate("/admin/blogs");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  const handleCategorySelector = (selectedOption) => {
    try {
      const allData = [];
      for (let i = 0; i < selectedOption.length; i++) {
        allData.push(selectedOption[i].value);
      }
      //console.log(allData.join(","));
      setSelectCategories(allData.join(","));
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findBlogHeading">Add New Blog Details</h1>
          <div className="blog-form-data">
            <div className="insert-blog-form">
              <div className="input-group">
                <label htmlFor="">Main Categories *</label>
                <Select
                  components={animatedComponents}
                  options={[...mainCategories]
                    .sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map((category, index) => {
                      return {
                        key: index,
                        value: category.title,
                        label: category.title,
                      };
                    })}
                  isMulti
                  closeMenuOnSelect={true}
                  onChange={handleCategorySelector}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Select Website *</label>
                <Select
                  name="Website"
                  components={animatedComponents}
                  options={allWebsites
                    .sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map((website, index) => {
                      return {
                        key: index,
                        value: website.title,
                        label: website.title,
                      };
                    })}
                  closeMenuOnSelect={true}
                  onChange={(event) => setSelectWebsite(event.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Blog Title *</label>
                <input
                  type="text"
                  placeholder="Blog Title"
                  required
                  value={blogTitle}
                  onChange={(event) => {
                    setBlogTitle(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Feature Image *</label>
                <input
                  type="file"
                  name="featureImage"
                  onChange={(event) => setFeatureImage(event.target.files[0])}
                  size="lg"
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Meta Title (60-70 Characters)*</label>
                <input
                  type="text"
                  placeholder="Meta Title"
                  required
                  value={metaTitle}
                  onChange={(event) => {
                    setMetaTitle(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Meta Keyword (Min-5 & Max-10)*</label>
                <input
                  type="text"
                  placeholder="Keywords"
                  required
                  value={metaKeyword}
                  onChange={(event) => {
                    setMetaKeyword(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">
                  Meta Description (160-300 Characters) *
                </label>
                <textarea
                  placeholder="Write Meta Description..."
                  required
                  rows="5"
                  value={metaDescription}
                  onChange={(event) => {
                    setMetaDescription(event.target.value);
                  }}
                ></textarea>
              </div>
              <div className="input-group blog-description">
                <label htmlFor="">Blog Description *</label>
                <SunEditor
                  autoFocus={true}
                  placeholder="Enter Blog Descriptions"
                  onChange={blogContentHandler}
                  setOptions={{
                    minHeight: "80vh",
                    maxHeight: "auto",
                    buttonList: buttonList.complex,
                  }}
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={insertResultData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/blogs`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertBlogsForm;
