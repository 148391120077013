import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const SubjectBoard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [filterSubjects, setFilterSubjects] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}subjects`, config)
        .then((response) => {
          //console.log(response.data);
          setSubjects(response.data);
          setFilterSubjects(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setSubjects, setFilterSubjects]);

  useEffect(() => {
    try {
      const filteredItems = subjects.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.AdminId.toString().includes(search)
      );

      setFilterSubjects(filteredItems);
    } catch (error) {
      alert(error.message);
    }
  }, [search, subjects]);

  const deleteHandler = (deleteId) => {
    try {
      setDeletedLoading(true);
      const id = deleteId;
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .delete(`${API_URL}subjects/delete/${id}`, config)
        .then((response) => {
          //console.log(response.data);
          setSubjects(subjects.filter((subject) => subject.id !== id));
          alert("Data Deleted Successfully!");
          setDeletedLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setDeletedLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setDeletedLoading(false);
    }
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => index + 1 + pageSize * (currentPage - 1),
      width: "70px",
    },
    {
      id: "courseTitle",
      name: "Subject Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row, index) => (
        <div
          className="hover-cell"
          title={row.title}
          onMouseEnter={() => handleRowHover(index)}
          onMouseLeave={() => handleRowHover(null)}
        >
          {hoveredRowIndex === index ? row.title : truncateText(row.title, 250)}
        </div>
      ),
    },
    {
      name: "Added By",
      selector: (row) => row.Admin.username,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="updateButton">
          <NavLink to={`/admin/edit-subject/${row.id}`}>
            <img src="../images/basic-icons/edit-icon.png" alt="Edit Icon" />
          </NavLink>
          <button
            onClick={() => deleteHandler(row.id)}
            disabled={deletedLoading}
            className={deletedLoading ? "disableBtn" : null}
          >
            {deletedLoading ? (
              <div className="countSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <img
                src={`${BASE_URL}images/basic-icons/delete-icon.png`}
                alt="Delete Icon"
              />
            )}
          </button>
        </div>
      ),
      width: "120px",
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };
  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="itemContainer">
            <div className="addButton">
              <h1>Subjects</h1>
              <div className="tableBtn">
                <NavLink to="/admin/add-subject">Add Subject</NavLink>
                &nbsp;&nbsp;
                <NavLink to="/admin/dashboard">Dashboard</NavLink>
              </div>
            </div>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={filterSubjects.length >= 0 ? filterSubjects : subjects}
                pagination
                responsive
                striped
                fixedHeader
                fixedHeaderScrollHeight="450px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    className="searchInput"
                    placeholder="Search Here"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                }
                defaultSortFieldId="courseTitle"
                defaultSortAsc={true}
                paginationPerPage={pageSize}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                paginationTotalRows={filterSubjects.length || subjects.length}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                  setPageSize(currentRowsPerPage);
                  setCurrentPage(currentPage);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubjectBoard;
