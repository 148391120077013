import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Back from "../common/back/Back";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import Counters from "../counter/Counters";
import Features from "../features/Features";
import Staff from "../staff/Staff";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const About = () => {
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>About us | {`${settings.metaTitle}`}</title>
        <link rel="canonical" href={`${BASE_URL}about/`} />
        <meta name="description" content={`${settings.metaDescription}`} />
        <meta name="keywords" content={`${settings.metaKeyword}`} />
      </Helmet>
      <Back title="About Us" />
      <div className="aboutUs">
        <Staff />
        <Features />
        <Counters />
      </div>
    </>
  );
};

export default About;
