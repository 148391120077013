import axios from "axios";
import React, { useEffect, useState } from "react";
import Title from "../common/title/Title";
import { API_URL, WEBSITE } from "../constants/Constants";
import TeamCard from "./StaffCard";
import "./staff.css";

// const API_URL = "http://localhost:3001/";

const Team = () => {
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      {/* <Back title="Team" /> */}
      <section className="team">
        <div className="container">
          <div>
            <Title
              subtitle="Our Members"
              title={`Staff and Doctors of ${settings.title}`}
            />
          </div>
          <div className="subContainer grid">
            <TeamCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
