import React from 'react';
import './modals.css';

const BankModal = ({setBankModal}) => {
  return (
    <>
        <div className='backshadow'>
            <div className='custom-modal'>
                <div className='delete-icon' onClick={() => setBankModal(false)}>X</div>
                <div className='bank-details'>
                  <h2>Islamic Bank Bangladesh</h2>
                  <p><strong>Holder Name:</strong> Shahabuddin Ahammed</p>
                  <p><strong>Account No:</strong> 20402840284028402</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default BankModal;