import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./result.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const ResultView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { resultId } = useParams();
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [batch, setBatch] = useState("");
  const [studentId, setStudentId] = useState("");
  const [regId, setRegId] = useState("");
  const [session, setSession] = useState("");
  const [fullMark, setFullMark] = useState("");
  const [resultGPA, setResultGPA] = useState("");
  const [resultGrade, setResultGrade] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [picture, setPicture] = useState([]);
  const [settings, setSettings] = useState("");

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}result/edit/${resultId}`, config)
        .then((response) => {
          //console.log(response.data);
          setName(response.data.name);
          setFatherName(response.data.fatherName);
          setMotherName(response.data.motherName);
          setCourseTitle(response.data.courseTitle);
          setCourseDuration(response.data.courseDuration);
          setBatch(response.data.batch);
          setStudentId(response.data.studentId);
          setRegId(response.data.regId);
          setSession(response.data.session);
          setFullMark(response.data.fullMark);
          setResultGPA(response.data.resultGPA);
          setResultGrade(response.data.resultGrade);
          setPassingYear(response.data.passingYear);
          setPicture(response.data.picture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [resultId]);

  return (
    <>
      {isLoading ? (
        <div className="loadingSpinner">
          <img
            src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
            alt="Loading.."
          />
        </div>
      ) : (
        <section className="coursesCard view">
          <div className="container">
            <div className="result-details">
              <div className="picture">
                <div className="institute">
                  <img
                    src={`${API_URL}images/website/${settings.logo}`}
                    alt={settings.title}
                  />
                  <div>
                    <h2>{settings.title}</h2>
                    <p>{settings.subTitle}</p>
                    <h3>
                      Course Details: {courseTitle + " " + courseDuration}
                    </h3>
                  </div>
                </div>
                <img src={`${API_URL}images/results//${picture}`} alt={name} />
              </div>
              <div className="result-group">
                <h2>Student Name</h2>
                <span>:</span>
                <p>{name}</p>
              </div>
              <div className="result-group">
                <h2>Father's Name</h2>
                <span>:</span>
                <p>{fatherName}</p>
              </div>
              <div className="result-group">
                <h2>Mother's Name</h2>
                <span>:</span>
                <p>{motherName}</p>
              </div>
              <div className="result-group">
                <h2>Course Title</h2>
                <span>:</span>
                <p>{courseTitle}</p>
              </div>
              <div className="result-group">
                <h2>Course Duration</h2>
                <span>:</span>
                <p>{courseDuration}</p>
              </div>
              <div className="result-group">
                <h2>Batch</h2>
                <span>:</span>
                <p>{batch}</p>
              </div>
              <div className="result-group">
                <h2>Studnet ID</h2>
                <span>:</span>
                <p>{studentId}</p>
              </div>
              <div className="result-group">
                <h2>Registration No</h2>
                <span>:</span>
                <p>{regId}</p>
              </div>
              <div className="result-group">
                <h2>Session</h2>
                <span>:</span>
                <p>{session}</p>
              </div>
              <div className="result-group">
                <h2>Full Marks</h2>
                <span>:</span>
                <p>
                  {fullMark}
                </p>
              </div>
              <div className="result-group">
                <h2>Result in GPA</h2>
                <span>:</span>
                <p>{resultGPA} out of 5.00</p>
              </div>
              <div className="result-group">
                <h2>Result in Grade</h2>
                <span>:</span>
                <p>{resultGrade}</p>
              </div>
              <div className="result-group">
                <h2>Passing Year</h2>
                <span>:</span>
                <p>{passingYear}</p>
              </div>
            </div>
            <div className="submit-btn">
              <NavLink to={`${BASE_URL}admin/results`}>Back</NavLink>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ResultView;
