import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL } from "../constants/Constants";
import "./courseSession.css";

// const API_URL = "http://localhost:3001/";

const InsertCourseSession = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const courseSessionData = () => {
    try {
      setIsLoading(true);
      const data = {
        title: title,
      };

      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}course-session`, data, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(`This course session is already exit!`);
        }
        //console.log(response.data);
        setTitle("");
        alert("Course Session Added Successfully!");
        setIsLoading(false);
        navigate("/admin/course-sessions");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findSessionHeading">Add New Course Session</h1>
          <div className="session-form-data">
            <div className="insert-session-form grid">
              <div className="input-group">
                <label htmlFor="">Course Session *</label>
                <input
                  type="text"
                  placeholder="Course Session (Jan-22 to Mar-23)"
                  required
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={courseSessionData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to="/admin/course-sessions">Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertCourseSession;
