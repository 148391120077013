import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const StaffCard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [staff, setstaff] = useState([]);
  const [settings, setSettings] = useState("");

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}teams`)
        .then((response) => {
          //console.log(response.data);
          setstaff(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loadingSpinner">
          <img
            src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
            alt="Loading.."
          />
        </div>
      ) : (
        staff.map((team, index) => (
          <div
            className={
              staff.length > 1 ? "items shadow" : "items shadow courseOnlyOne"
            }
            key={index}
          >
            <div className="img">
              <img
                src={`${API_URL}images/staff/${team.profilePicture}`}
                alt={team.fullName}
              />
              <div className="overlay">
                <a
                  href={team.facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f icon"></i>
                </a>
                <a
                  href={team.instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram icon"></i>
                </a>
                <a
                  href={team.twitterLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter icon"></i>
                </a>
                <a
                  href={team.linkedinLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin icon"></i>
                </a>
              </div>
            </div>
            <div className="details">
              <h2>{team.fullName}</h2>
              <span>{team.eduQualification}</span>
              <br />
              {/* <span>{ team.phone }</span> */}
              <p>{team.designation}</p>
              <h3>{settings.title}</h3>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default StaffCard;
