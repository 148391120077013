import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./courseDuration.css";

// const API_URL = "http://localhost:3001/";

const EditDuration = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { durationId } = useParams();
  const [title, setTitle] = useState("");
  const [totalMonths, setTotalMonths] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}course-duration/edit/${durationId}`, config)
        .then((response) => {
          //console.log(response.data);
          setTitle(response.data.title);
          setTotalMonths(response.data.totalMonths);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [durationId]);

  const updateData = () => {
    try {
      setUpdatedLoading(true);
      const data = {
        title,
        totalMonths,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}course-duration/edit/${durationId}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(`This course duration is already exit!`);
          }
          // console.log(response.data);
          setTitle("");
          setTotalMonths("");
          alert("Course Duration Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/course-durations");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCourseHeading">Update Course Duration</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="course-form-data">
              <div className="insert-course-form grid">
                <div className="input-group">
                  <label htmlFor="">Course Duration *</label>
                  <input
                    type="text"
                    placeholder="Course Duration (6 Months)"
                    required
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Total Months (Number) *</label>
                  <input
                    type="text"
                    placeholder="Total Months (6, 12, 24)"
                    required
                    value={totalMonths}
                    onChange={(event) => {
                      setTotalMonths(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/course-durations`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditDuration;
