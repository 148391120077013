import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./category.css";

// const API_URL = "http://localhost:3001/";

const InsertCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [coverPicture, setCoverPicture] = useState("");
  const navigate = useNavigate();

  const categoryData = () => {
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("coverPicture", coverPicture);

      // console.log(formData);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}categories`, formData, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(response.data.error);
        }
        //console.log(response.data);
        alert("Category Data Added Successfully!");
        setTitle("");
        setCoverPicture("");
        setIsLoading(false);
        navigate("/admin/categories");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCategoryHeading">Add New Category</h1>
          <div className="category-form-data">
            <div className="insert-category-form grid">
              <div className="input-group">
                <label htmlFor="">Category Title *</label>
                <input
                  type="text"
                  placeholder="Category Title (Pharmacy, Dental)"
                  required
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Cover Picture *</label>
                <input
                  type="file"
                  name="coverPicture"
                  onChange={(event) => setCoverPicture(event.target.files[0])}
                  size="lg"
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={categoryData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/categories`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertCategory;
