import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./admin.css";
// const API_URL = "http://localhost:3001/";

const options = [
  { value: "Admin", label: "Admin" },
  { value: "User", label: "User" },
];

const taskOptions = [
  { value: "All", label: "All" },
  { value: "Dashboard", label: "Dashboard" },
  { value: "Students", label: "Students" },
  { value: "Batches", label: "Batches" },
  { value: "Categories", label: "Categories" },
  { value: "Courses", label: "Courses" },
  { value: "CourseDuration", label: "CourseDuration" },
  { value: "CourseSession", label: "CourseSession" },
  { value: "Requirements", label: "Requirements" },
  { value: "Subjects", label: "Subjects" },
  { value: "Results", label: "Results" },
  { value: "Blogs", label: "Blogs" },
  { value: "Staff", label: "Staff" },
  { value: "Youtube", label: "Youtube" },
  { value: "Reviews", label: "Reviews" },
  { value: "Features", label: "Features" },
  { value: "FAQs", label: "FAQs" },
  { value: "CategoryPosition", label: "CategoryPosition" },
  { value: "Counters", label: "Counters" },
  { value: "BasicSetting", label: "BasicSetting" },
  { value: "UserManage", label: "UserManage" },
];

const animatedComponents = makeAnimated();

const EditUsers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { userID } = useParams();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [task, setTask] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}admins/edit-user/${userID}`, config)
        .then((response) => {
          //console.log(response.data);
          setFullName(response.data.fullName);
          setEmail(response.data.email);
          setRole(response.data.role);
          setTask(response.data.task);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [userID]);

  const EditData = () => {
    try {
      setUpdatedLoading(true);
      const data = {
        fullName,
        email,
        role,
        task,
      };
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };
      axios
        .put(`${API_URL}admins/edit-user/${userID}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(response.data.error);
          }
          setFullName("");
          setEmail("");

          alert("User Data Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/user-dashboard");
        });
    } catch (error) {
      setUpdatedLoading(false);
      alert(error.message);
    }
  };

  const handleTaskSelector = (selectedOption) => {
    try {
      const allData = [];
      for (let i = 0; i < selectedOption.length; i++) {
        allData.push(selectedOption[i].value);
      }
      //console.log(allData.join(","));
      setTask(allData.join(","));
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="login-form-data">
            <h1 className="findAdminHeading">Edit Admin/User</h1>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <>
                <div className="login-insert-form">
                  <div className="input-group">
                    <label htmlFor="">Full Name *</label>
                    <input
                      type="text"
                      placeholder="Full Name"
                      required
                      value={fullName}
                      onChange={(event) => {
                        setFullName(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">E-mail *</label>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      required
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Role *</label>
                    <Select
                      components={animatedComponents}
                      options={options}
                      closeMenuOnSelect={true}
                      onChange={(event) => setRole(event.value)}
                    />
                  </div>
                  <p>Selected Option: {role}</p>
                  <div className="input-group">
                    <label htmlFor="">Assign Task *</label>
                    <Select
                      components={animatedComponents}
                      options={taskOptions}
                      isMulti
                      closeMenuOnSelect={true}
                      onChange={handleTaskSelector}
                    />
                  </div>
                  <p>Selected Option: {task}</p>
                </div>
                <div className="submit-btn">
                  <button
                    onClick={EditData}
                    disabled={updatedLoading}
                    className={updatedLoading ? "disableBtn" : null}
                  >
                    {updatedLoading ? "Updating.." : "Update"}
                  </button>
                  <NavLink to={`${BASE_URL}admin/user-dashboard`}>Back</NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUsers;
