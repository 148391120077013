import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./feature.css";

// const API_URL = "http://localhost:3001/";

const EditFeature = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { featureId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [picture, setPicture] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}features/edit/${featureId}`, config)
        .then((response) => {
          //console.log(response.data);
          setTitle(response.data.title);
          setDescription(response.data.description);
          setPicture(response.data.picture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [featureId]);

  const updateFeatureData = () => {
    try {
      setUpdatedLoading(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);

      if (picture) {
        formData.append("picture", picture);
      } else {
        formData.append("picture", picture);
      }
      // console.log(formData);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}features/edit/${featureId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(`This feature is already exit!`);
          }
          //console.log(response.data);
          alert("Feature Updated Successfully!");
          setTitle("");
          setDescription("");
          setPicture("");
          setUpdatedLoading(false);
          navigate("/admin/features");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findSubjectHeading">Update Feature</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="insert-form-data">
              <div className="insert-feature-form">
                <div className="insert-input-group">
                  <div className="input-group">
                    <label htmlFor="">Feature Title*</label>
                    <input
                      type="text"
                      placeholder="Feature Title"
                      required
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Feature Picture *</label>
                    <div className="editProfilePicture">
                      <input
                        type="file"
                        name="picture"
                        onChange={(event) => setPicture(event.target.files[0])}
                        size="lg"
                      />
                      <img
                        src={`${API_URL}images/features/${picture}`}
                        alt={title}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="">Description *</label>
                  <textarea
                    placeholder="Write Feature Details..."
                    required
                    rows="10"
                    value={description}
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateFeatureData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/features`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditFeature;
