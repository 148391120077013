import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CourseCard from "../allcourse/CourseCard";
import YoutubeCourse from "../allcourse/YoutubeCourse";
import Back from "../common/back/Back";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";

const CourseHome = () => {
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Courses | {`${settings.metaTitle}`}</title>
        <link rel="canonical" href={`${BASE_URL}about`} />
        <meta name="description" content={`${settings.metaDescription}`} />
        <meta name="keywords" content={`${settings.metaKeyword}`} />
      </Helmet>
      <Back title="Explore Courses" />
      <CourseCard />
      <YoutubeCourse />
    </>
  );
};

export default CourseHome;
