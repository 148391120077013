import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./course.css";

// const API_URL = "http://localhost:3001/";

const animatedComponents = makeAnimated();

const EditCourse = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { category, slug } = useParams();
  const [courseID, setCourseID] = useState(null);
  const [categories, setCategories] = useState([]);
  const [courseDuration, setCourseDuration] = useState([]);
  const [requires, setRequires] = useState([]);
  const [allSubject, setAllSubject] = useState([]);
  const [title, setTitle] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectDuration, setSelectDuration] = useState("");
  const [totalSubject, setTotalSubject] = useState("");
  const [totalClass, setTotalClass] = useState("");
  const [fullMark, setFullMark] = useState("");
  const [admissionFee, setAdmissionFee] = useState("");
  const [monthlyFee, setMonthlyFee] = useState("");
  const [totalExamNumber, setTotalExamNumber] = useState("");
  const [examFee, setExamFee] = useState("");
  const [selectRequire, setSelectRequire] = useState("");
  const [selectSubject, setSelectSubject] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}categories`, config)
        .then((response) => {
          //console.log(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}course-duration`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseDuration(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}requires`, config)
        .then((response) => {
          //console.log(response.data);
          setRequires(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });

      axios
        .get(`${API_URL}subjects`, config)
        .then((response) => {
          //console.log(response.data);
          setAllSubject(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCategories, setCourseDuration, setRequires, setAllSubject]);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}courses/${category}/${slug}`, config)
        .then((response) => {
          //console.log(response.data);
          setCourseID(response.data.id);
          setTitle(response.data.title);
          setSelectCategory(response.data.category);
          setSelectDuration(response.data.courseDuration);
          setTotalSubject(response.data.totalSubject);
          setTotalClass(response.data.totalClass);
          setFullMark(response.data.totalMark);
          setAdmissionFee(response.data.admissionFee);
          setMonthlyFee(response.data.monthlyFee);
          setTotalExamNumber(response.data.examNumber);
          setExamFee(response.data.examFee);
          setSelectRequire(response.data.courseRequirement);
          setSelectSubject(response.data.courseSubject);
          setMetaTitle(response.data.metaTitle);
          setMetaKeyword(response.data.metaKeyword);
          setMetaDescription(response.data.metaDescription);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [category, slug]);

  const updateCourseData = () => {
    const slug = metaTitle.toLowerCase().split(" ").join("-");
    try {
      setUpdatedLoading(true);
      const data = {
        title,
        category: selectCategory,
        courseDuration: selectDuration,
        totalSubject,
        totalClass,
        totalMark: fullMark,
        admissionFee,
        monthlyFee,
        examNumber: totalExamNumber,
        examFee,
        courseRequirement: selectRequire,
        courseSubject: selectSubject,
        metaTitle,
        slug,
        metaKeyword,
        metaDescription,
      };

      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}courses/edit-course/${courseID}`, data, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(`This subject is already exit!`);
          }
          //console.log(response.data);
          setTitle("");
          setSelectCategory("");
          setSelectDuration("");
          setTotalSubject("");
          setTotalClass("");
          setFullMark("");
          setAdmissionFee("");
          setMonthlyFee("");
          setTotalExamNumber("");
          setExamFee("");
          setSelectRequire("");
          setSelectSubject("");
          setMetaTitle("");
          setMetaKeyword("");
          setMetaDescription("");
          alert("Course Data Updated Successfully!");
          setUpdatedLoading(false);
          navigate("/admin/courses");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  const handleRequirementSelector = (selectedOption) => {
    try {
      const allData = [];
      for (let i = 0; i < selectedOption.length; i++) {
        allData.push(selectedOption[i].value);
      }
      //console.log(allData.join(","));
      setSelectRequire(allData.join(","));
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSubjectSelector = (selectedOption) => {
    try {
      const allData = [];
      for (let i = 0; i < selectedOption.length; i++) {
        allData.push(selectedOption[i].value);
      }
      //console.log(allData.join(","));
      setSelectSubject(allData.join(","));
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findCourseHeading">Add New Course Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="form-data">
              <div className="course-form">
                <div className="input-group">
                  <label htmlFor="">Course Title *</label>
                  <input
                    type="text"
                    placeholder="Course Title"
                    required
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Main Category *</label>
                  <Select
                    components={animatedComponents}
                    options={categories.map(function (category, index) {
                      return {
                        key: index,
                        value: category.title,
                        label: category.title,
                      };
                    })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectCategory(event.value)}
                  />
                  <p>Selected Options: {selectCategory}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Duration *</label>
                  <Select
                    name="CourseDuration"
                    components={animatedComponents}
                    options={courseDuration.map(function (duration, index) {
                      return {
                        key: index,
                        value: duration.totalMonths,
                        label: duration.title,
                      };
                    })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectDuration(event.value)}
                  />
                  <p>Selected Options: {selectDuration}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Total Subject *</label>
                  <input
                    type="text"
                    placeholder="Example: 5"
                    required
                    value={totalSubject}
                    onChange={(event) => {
                      setTotalSubject(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Total Classes *</label>
                  <input
                    type="text"
                    placeholder="Example: 24"
                    required
                    value={totalClass}
                    onChange={(event) => {
                      setTotalClass(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Per Subject Mark *</label>
                  <input
                    type="text"
                    placeholder="Example: 100"
                    required
                    value={fullMark}
                    onChange={(event) => {
                      setFullMark(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Admission Fee *</label>
                  <input
                    type="text"
                    placeholder="Example: 7500"
                    required
                    value={admissionFee}
                    onChange={(event) => {
                      setAdmissionFee(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Monthly Fee *</label>
                  <input
                    type="text"
                    placeholder="Example: 2000"
                    required
                    value={monthlyFee}
                    onChange={(event) => {
                      setMonthlyFee(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Total Exam. Number *</label>
                  <input
                    type="text"
                    placeholder="Example: 4"
                    required
                    value={totalExamNumber}
                    onChange={(event) => {
                      setTotalExamNumber(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Per Exam. Fee *</label>
                  <input
                    type="text"
                    placeholder="Example: 2000"
                    required
                    value={examFee}
                    onChange={(event) => {
                      setExamFee(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Course Requirements *</label>
                  <Select
                    components={animatedComponents}
                    options={requires.map(function (require, index) {
                      return {
                        key: index,
                        value: require.title,
                        label: require.title,
                      };
                    })}
                    isMulti
                    closeMenuOnSelect={true}
                    onChange={handleRequirementSelector}
                  />
                  <p>Selected Options: {selectRequire}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Select Course Subjects *</label>
                  <Select
                    components={animatedComponents}
                    options={allSubject.map(function (subject, index) {
                      return {
                        key: index,
                        value: subject.title,
                        label: subject.title,
                      };
                    })}
                    isMulti
                    closeMenuOnSelect={true}
                    onChange={handleSubjectSelector}
                  />
                  <p>Selected Options: {selectSubject}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Meta Title (60-70 Characters)*</label>
                  <input
                    type="text"
                    placeholder="Meta Title"
                    required
                    value={metaTitle}
                    onChange={(event) => {
                      setMetaTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Meta Keyword (Min-5 & Max-10)*</label>
                  <input
                    type="text"
                    placeholder="Keywords"
                    required
                    value={metaKeyword}
                    onChange={(event) => {
                      setMetaKeyword(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">
                    Meta Description (160-300 Characters)*
                  </label>
                  <textarea
                    placeholder="Meta Description"
                    required
                    rows={5}
                    value={metaDescription}
                    onChange={(event) => {
                      setMetaDescription(event.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateCourseData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/courses`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCourse;
