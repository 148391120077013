import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { API_URL, BASE_URL, WEBSITE } from "../constants/Constants";
import "./result.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const ResultForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showResultLoading, setShowResultLoading] = useState(false);
  const [name, setName] = useState("");
  const [regId, setRegId] = useState("");
  const [result, setResult] = useState({});
  const [settings, setSettings] = useState("");
  const ref = useRef();

  const resultData = () => {
    try {
      setIsLoading(true);
      setShowResultLoading(true);
      const data = {
        name: name.trim(),
        regId: regId.trim(),
      };
      axios.post(`${API_URL}result/find-result`, data).then((response) => {
        if (response.data.error) {
          setShowResultLoading(false);
          setIsLoading(false);
          setResult({});
          return alert(response.data.error);
        }
        setResult(response.data);
        //console.log(response.data);
        setName("");
        setRegId("");
        setShowResultLoading(false);
        setIsLoading(false);
      });
    } catch (error) {
      alert(error.message);
      setShowResultLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, []);

  return (
    <>
      <div className="resultBg">
        <div className="container">
          <div className="subContainer">
            <div className="form-data">
              <div className="result-form">
                <div className="input-group">
                  <label htmlFor="">Name *</label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    required
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Registration *</label>
                  <input
                    type="text"
                    placeholder="Enter registration number"
                    required
                    value={regId}
                    onChange={(event) => {
                      setRegId(event.target.value);
                    }}
                  />
                </div>
                <div className="submit-btn">
                  <button
                    onClick={resultData}
                    disabled={isLoading}
                    className={isLoading ? "disableBtn" : null}
                  >
                    {isLoading ? "Submitting.." : "Submit"}
                  </button>
                </div>
              </div>
            </div>

            {Object.keys(result).length !== 0 ? (
              <>
                {showResultLoading ? (
                  <div className="loadingSpinner">
                    <img
                      src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                      alt="Loading.."
                    />
                  </div>
                ) : (
                  <div className="result-details" ref={ref}>
                    <div className="picture">
                      <div className="institute">
                        <img
                          src={`${API_URL}images/website/${settings.logo}`}
                          alt={settings.title}
                        />
                        <div className="resultWebTitle">
                          <h2>{settings.title}</h2>
                          <p>{settings.subTitle}</p>
                          <div className="resultCourseTitle">
                            <p>
                              <strong>Course Details:&nbsp;</strong>
                              {result.courseTitle + " " + result.courseDuration}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="studentPicture">
                        <img
                          src={`${API_URL}images/results/${result.picture}`}
                          alt={result.name}
                        />
                      </div>
                    </div>
                    <div className="result-group">
                      <h2>Student Name</h2>
                      <span>:</span>
                      <p>{result.name}</p>
                    </div>
                    <div className="result-group">
                      <h2>Father's Name</h2>
                      <span>:</span>
                      <p>{result.fatherName}</p>
                    </div>
                    <div className="result-group">
                      <h2>Mother's Name</h2>
                      <span>:</span>
                      <p>{result.motherName}</p>
                    </div>
                    <div className="result-group">
                      <h2>Course Title</h2>
                      <span>:</span>
                      <p>{result.courseTitle}</p>
                    </div>
                    <div className="result-group">
                      <h2>Course Duration</h2>
                      <span>:</span>
                      <p>{result.courseDuration}</p>
                    </div>
                    <div className="result-group">
                      <h2>Batch</h2>
                      <span>:</span>
                      <p>{result.batch}</p>
                    </div>
                    <div className="result-group">
                      <h2>Studnet ID</h2>
                      <span>:</span>
                      <p>{result.studentId}</p>
                    </div>
                    <div className="result-group">
                      <h2>Registration No</h2>
                      <span>:</span>
                      <p>{result.regId}</p>
                    </div>
                    <div className="result-group">
                      <h2>Session</h2>
                      <span>:</span>
                      <p>{result.session}</p>
                    </div>
                    <div className="result-group">
                      <h2>Full Marks</h2>
                      <span>:</span>
                      <p>
                        {result.fullMark}
                      </p>
                    </div>
                    <div className="result-group">
                      <h2>Result in GPA</h2>
                      <span>:</span>
                      <p>{result.resultGPA} out of 5.00</p>
                    </div>
                    <div className="result-group">
                      <h2>Result in Grade</h2>
                      <span>:</span>
                      <p>{result.resultGrade}</p>
                    </div>
                    <div className="result-group">
                      <h2>Passing Year</h2>
                      <span>:</span>
                      <p>{result.passingYear}</p>
                    </div>
                  </div>
                )}

                <div className="printBtn">
                  <ReactToPrint
                    trigger={() => {
                      return <button>Print Result Sheet</button>;
                    }}
                    content={() => ref.current}
                    documentTitle={`Result Sheet of ${result.name} from ${settings.title} in ${result.passingYear}`}
                    pageStyle="print"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultForm;
