import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";

// const API_URL = "http://localhost:3001/";

const RequirementBoard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [filterRequirements, setFilterRequirements] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}requires`, config)
        .then((response) => {
          //console.log(response.data);
          setRequirements(response.data);
          setFilterRequirements(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setRequirements, setFilterRequirements]);

  useEffect(() => {
    try {
      const filteredItems = requirements.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.AdminId.toString().includes(search)
      );

      setFilterRequirements(filteredItems);
    } catch (error) {
      alert(error.message);
    }
  }, [search, requirements]);

  const deleteHandler = (deleteId) => {
    try {
      setDeletedLoading(true);
      const id = deleteId;
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .delete(`${API_URL}requires/delete/${id}`, config)
        .then((response) => {
          //console.log(response.data);
          setRequirements(
            requirements.filter((requirement) => requirement.id !== id)
          );
          alert("Data Deleted Successfully!");
          setDeletedLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setDeletedLoading(false);
        });
    } catch (error) {
      alert(error.message);
      setDeletedLoading(false);
    }
  };

  const columns = [
    {
      name: "S. No",
      cell: (row, index) => index + 1,
      width: "70px",
    },
    {
      id: "requirementTitle",
      name: "Requirement Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Added By",
      selector: (row) => row.Admin.username,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="updateButton">
          <NavLink to={`/admin/edit-requirement/${row.id}`}>
            <img src="../images/basic-icons/edit-icon.png" alt="Edit Icon" />
          </NavLink>
          <button
            onClick={() => deleteHandler(row.id)}
            disabled={deletedLoading}
            className={deletedLoading ? "disableBtn" : null}
          >
            {deletedLoading ? (
              <div className="countSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <img
                src={`${BASE_URL}images/basic-icons/delete-icon.png`}
                alt="Delete Icon"
              />
            )}
          </button>
        </div>
      ),
      width: "120px",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="itemContainer">
            <div className="addButton">
              <h1>Requirements</h1>
              <div className="tableBtn">
                <NavLink to="/admin/add-requirement">Add Requirement</NavLink>
                &nbsp;&nbsp;
                <NavLink to="/admin/dashboard">Dashboard</NavLink>
              </div>
            </div>
            {isLoading ? (
              <div className="loadingSpinner">
                <img
                  src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                  alt="Loading.."
                />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={
                  filterRequirements.length >= 0
                    ? filterRequirements
                    : requirements
                }
                pagination
                responsive
                striped
                fixedHeader
                fixedHeaderScrollHeight="450px"
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    className="searchInput"
                    placeholder="Search Here"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                }
                defaultSortFieldId="requirementTitle"
                defaultSortAsc={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequirementBoard;
