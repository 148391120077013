import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./youtube.css";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const animatedComponents = makeAnimated();

const EditYoutube = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedLoading, setUpdatedLoading] = useState(false);
  const { youtubeId } = useParams();
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [playlistTitle, setPlaylistTitle] = useState("");
  const [playlistLink, setPlaylistLink] = useState("");
  const [videoNumber, setVideoNumber] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}categories`, config)
        .then((response) => {
          //console.log(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setCategories]);

  useEffect(() => {
    try {
      const config = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .get(`${API_URL}youtubes/edit/${youtubeId}`, config)
        .then((response) => {
          //console.log(response.data);
          setSelectCategory(response.data.mainCategory);
          setPlaylistTitle(response.data.playlistTitle);
          setPlaylistLink(response.data.playlistLink);
          setVideoNumber(response.data.totalVideoNumber);
          setThumbnail(response.data.thumbnailPicture);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [youtubeId]);

  const updateYoutubeData = () => {
    try {
      setUpdatedLoading(true);
      var formData = new FormData();
      formData.append("mainCategory", selectCategory);
      formData.append("playlistTitle", playlistTitle);
      formData.append("totalVideoNumber", videoNumber);
      formData.append("playlistLink", playlistLink);

      if (thumbnail) {
        formData.append("thumbnailPicture", thumbnail);
      } else {
        formData.append("thumbnailPicture", thumbnail);
      }

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put(`${API_URL}youtubes/edit/${youtubeId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setUpdatedLoading(false);
            return alert(response.data.error);
          }
          //console.log(response.data);
          alert("Youtube Playlist Updated Successfully!");
          setSelectCategory("");
          setPlaylistTitle("");
          setVideoNumber("");
          setPlaylistLink("");
          setThumbnail("");
          setUpdatedLoading(false);
          navigate("/admin/youtube");
        });
    } catch (error) {
      alert(error.message);
      setUpdatedLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findYoutubeHeading">Edit YouTube Playlist Details</h1>
          {isLoading ? (
            <div className="loadingSpinner">
              <img
                src={`${BASE_URL}images/basic-icons/spinner-loading.gif`}
                alt="Loading.."
              />
            </div>
          ) : (
            <div className="youtube-form-data">
              <div className="insert-youtube-form">
                <div className="input-group">
                  <label htmlFor="">Main Category *</label>
                  <Select
                    components={animatedComponents}
                    options={[...categories]
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map(function (category, index) {
                        return {
                          key: index,
                          value: category.title,
                          label: category.title,
                        };
                      })}
                    closeMenuOnSelect={true}
                    onChange={(event) => setSelectCategory(event.value)}
                  />
                  <p>Select Option: {selectCategory}</p>
                </div>
                <div className="input-group">
                  <label htmlFor="">Playlist Title *</label>
                  <input
                    type="text"
                    placeholder="Playlist Title"
                    required
                    value={playlistTitle}
                    onChange={(event) => {
                      setPlaylistTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Total Video Number *</label>
                  <input
                    type="text"
                    placeholder="Total playlist video number"
                    required
                    value={videoNumber}
                    onChange={(event) => {
                      setVideoNumber(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Playlist Link *</label>
                  <input
                    type="text"
                    placeholder="Playlist Link"
                    required
                    value={playlistLink}
                    onChange={(event) => {
                      setPlaylistLink(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Playlist Thumbnail *</label>
                  <div className="editProfilePicture">
                    <input
                      type="file"
                      name="thumbnail"
                      onChange={(event) => setThumbnail(event.target.files[0])}
                      size="lg"
                    />
                    <img
                      src={`${API_URL}images/youtubes/${thumbnail}`}
                      alt={playlistTitle}
                    />
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  onClick={updateYoutubeData}
                  disabled={updatedLoading}
                  className={updatedLoading ? "disableBtn" : null}
                >
                  {updatedLoading ? "Updating.." : "Update"}
                </button>
                <NavLink to={`${BASE_URL}admin/youtube`}>Back</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditYoutube;
