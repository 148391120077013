import React from 'react';
import './modals.css';

const RocketModal = ({setRocketModal}) => {
  return (
    <>
        <div className='backshadow'>
            <div className='custom-modal'>
                <div className='delete-icon' onClick={() => setRocketModal(false)}>X</div>
                <div className='bkashQRCode'>
                    <img src="./images/payments/personal-bkash-qr-code.jpg" alt='test' />
                </div>
            </div>
        </div>
    </>
  )
}

export default RocketModal;