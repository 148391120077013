import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./admin.css";
// const API_URL = "http://localhost:3001/";

const options = [
  { value: "Admin", label: "Admin" },
  { value: "User", label: "User" },
];

const taskOptions = [
  { value: "All", label: "All" },
  { value: "Dashboard", label: "Dashboard" },
  { value: "Students", label: "Students" },
  { value: "Batches", label: "Batches" },
  { value: "Categories", label: "Categories" },
  { value: "Courses", label: "Courses" },
  { value: "CourseDuration", label: "CourseDuration" },
  { value: "CourseSession", label: "CourseSession" },
  { value: "Requirements", label: "Requirements" },
  { value: "Subjects", label: "Subjects" },
  { value: "Results", label: "Results" },
  { value: "Blogs", label: "Blogs" },
  { value: "Staff", label: "Staff" },
  { value: "Youtube", label: "Youtube" },
  { value: "Reviews", label: "Reviews" },
  { value: "Features", label: "Features" },
  { value: "FAQs", label: "FAQs" },
  { value: "CategoryPosition", label: "CategoryPosition" },
  { value: "Counters", label: "Counters" },
  { value: "BasicSetting", label: "BasicSetting" },
  { value: "UserManage", label: "UserManage" },
];

const animatedComponents = makeAnimated();

const AddUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [task, setTask] = useState("");
  const [textError, setTextError] = useState("");
  const navigate = useNavigate();

  const addData = () => {
    try {
      setIsLoading(true);
      if (password === confirmPassword) {
        setTextError("");
        const data = {
          fullName,
          email,
          password,
          username,
          role,
          task,
        };
        const config = {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        };
        axios.post(`${API_URL}admins`, data, config).then((response) => {
          if (response.data.error) {
            setIsLoading(false);
            return alert(response.data.error);
          }
          if (response.data.message === "User added successfully") {
            setFullName("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setUsername("");
            setIsLoading(false);
            navigate("/admin/user-dashboard");
          }
        });
      } else {
        setIsLoading(false);
        setTextError(`Password don't match!`);
      }
    } catch (error) {
      setIsLoading(false);
      alert(error.message);
    }
  };

  const handleTaskSelector = (selectedOption) => {
    try {
      const allData = [];
      for (let i = 0; i < selectedOption.length; i++) {
        allData.push(selectedOption[i].value);
      }
      //console.log(allData.join(","));
      setTask(allData.join(","));
    } catch (error) {
      alert(error.message);
    }
  };

  // console.log(task);

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <div className="login-form-data">
            <h1 className="findAdminHeading">Add Admin/User</h1>
            <div className="login-insert-form">
              <div className="input-group">
                <label htmlFor="">Full Name *</label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  required
                  value={fullName}
                  onChange={(event) => {
                    setFullName(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">E-mail *</label>
                <input
                  type="text"
                  placeholder="Enter your email"
                  required
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Password *</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  required
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Confirm Password *</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  required
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Username *</label>
                <input
                  type="text"
                  placeholder="Use unique username"
                  required
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Role *</label>
                <Select
                  components={animatedComponents}
                  options={options}
                  closeMenuOnSelect={true}
                  onChange={(event) => setRole(event.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="">Assign Task *</label>
                <Select
                  components={animatedComponents}
                  options={taskOptions}
                  isMulti
                  closeMenuOnSelect={true}
                  onChange={handleTaskSelector}
                />
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={addData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/user-dashboard`}>Back</NavLink>
            </div>
            <div className="textError">{textError}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUsers;
