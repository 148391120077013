import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_URL, BASE_URL } from "../constants/Constants";
import "./feature.css";

// const API_URL = "http://localhost:3001/";

const InsertFeatures = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [picture, setPicture] = useState("");
  const navigate = useNavigate();

  const insertFeatureData = () => {
    try {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("picture", picture);

      // console.log(formData);

      const config = {
        headers: {
          "Content-Tyoe": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios.post(`${API_URL}features`, formData, config).then((response) => {
        if (response.data.error) {
          setIsLoading(false);
          return alert(`This feature is already exit!`);
        }
        //console.log(response.data);
        alert("Feature Added Successfully!");
        setTitle("");
        setDescription("");
        setPicture("");
        setIsLoading(false);
        navigate("/admin/features");
      });
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="subContainer">
          <h1 className="findSubjectHeading">Add New Feature</h1>
          <div className="insert-form-data">
            <div className="insert-feature-form">
              <div className="insert-input-group">
                <div className="input-group">
                  <label htmlFor="">Feature Title*</label>
                  <input
                    type="text"
                    placeholder="Feature Title"
                    required
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">Feature Picture *</label>
                  <input
                    type="file"
                    name="picture"
                    onChange={(event) => setPicture(event.target.files[0])}
                    size="lg"
                  />
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="">Description *</label>
                <textarea
                  placeholder="Write Feature Details..."
                  required
                  rows="10"
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="submit-btn">
              <button
                onClick={insertFeatureData}
                disabled={isLoading}
                className={isLoading ? "disableBtn" : null}
              >
                {isLoading ? "Saving.." : "Save"}
              </button>
              <NavLink to={`${BASE_URL}admin/features`}>Back</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsertFeatures;
